export enum WorkflowType {
  WORKSPACE_ONBOARDING = 'WORKSPACE_ONBOARDING',
}

export interface Workflow<T extends object> {
  workflow_id?: string;
  workspace_id?: string;
  workflow_type: WorkflowType;
  created_at?: number;
  modified_at?: number;
  state: T;
}
export enum WorkspaceOnboardingStep {
  CONNECT_APPS = 'CONNECT_APPS',
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export abstract class WorkspaceOnboardingStepUtils {
  public static titles: Record<WorkspaceOnboardingStep, string> = {
    [WorkspaceOnboardingStep.CONNECT_APPS]: 'Connect Apps',
  };

  public static toasterFailureMessages: Record<
    WorkspaceOnboardingStep,
    string
  > = {
    [WorkspaceOnboardingStep.CONNECT_APPS]:
      'Connect at least one 💬 Dashworks data source to continue',
  };

  public static findStep(
    stepString: string | undefined
  ): WorkspaceOnboardingStep | undefined {
    return ALL_WORKSPACE_STEPS.find(
      (step) => WorkspaceOnboardingStep[step] === stepString
    );
  }
}

export const ALL_WORKSPACE_STEPS: WorkspaceOnboardingStep[] = [
  WorkspaceOnboardingStep.CONNECT_APPS,
];

export interface WorkspaceWorkflowState {
  isCompanyDetailsFilled: boolean;
  hasSkippedDirectorySync: boolean;
  stripeCheckoutSessionId?: string;
}

export const DEFAULT_WORKSPACE_WORKFLOW_STATE: WorkspaceWorkflowState = {
  isCompanyDetailsFilled: false,
  hasSkippedDirectorySync: false,
};
