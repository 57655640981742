import { useCallback, useEffect, useState } from 'react';
import {
  addOrgMembers,
  addOrgMembersSearchedQuery,
} from '../../redux/orgMembers/actions';
import { useDispatch } from '../../redux/store';
import { logError, uniqBy } from '../../scripts/utils';
import { invokeFastApi } from '../apis/fastapi';
import { MemberStatus, OrgMember } from '../models/org-member';
import { useGlobalState } from './redux';

interface MembersResponse {
  members: OrgMember[];
  allMembers: OrgMember[];
  loading: boolean;
  fetchMembersByQuery: (
    q: string,
    status?: MemberStatus
  ) => Promise<OrgMember[]>;
}

const filterMembersByQuery = (
  members: OrgMember[],
  query: string
): OrgMember[] => {
  return members.filter(
    (member) =>
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      member.name?.toLowerCase().includes(query.toLowerCase()) ||
      member.email.toLowerCase().includes(query.toLowerCase())
  );
};

export const useMembers = (): MembersResponse => {
  const dispatch = useDispatch();
  const { members: allMembers, searchedQueries } = useGlobalState(
    (state) => state.orgMembers
  );

  const [members, setMembers] = useState<OrgMember[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchMembersByQuery = useCallback(
    async (
      q: string,
      status?: MemberStatus,
      page = 1
    ): Promise<OrgMember[]> => {
      if (
        searchedQueries.some(
          (query) =>
            query.q === q && query.status === status && query.page === page
        )
      ) {
        const filteredMembers = filterMembersByQuery(allMembers, q);
        setMembers(filteredMembers);
        return filteredMembers;
      }

      try {
        setLoading(true);
        const { members: searchedMembers } = await invokeFastApi<{
          members: OrgMember[];
        }>({
          path: '/members',
          method: 'GET',
          queryParams: {
            page: 1,
            query: q,
            status,
          },
        });

        const allMergedMembers = uniqBy(
          [...allMembers, ...searchedMembers],
          'user_id'
        );

        dispatch(addOrgMembers(searchedMembers));
        dispatch(addOrgMembersSearchedQuery({ q, page: 1, status }));

        const filteredMembers = filterMembersByQuery(allMergedMembers, q);
        setMembers(filteredMembers);
        setLoading(false);
        return filteredMembers;
      } catch (error) {
        logError(error);
        setLoading(false);
      }

      const filteredMembers = filterMembersByQuery(allMembers, q);
      setMembers(filteredMembers);
      return filteredMembers;
    },
    [allMembers, dispatch, searchedQueries]
  );

  useEffect(() => {
    fetchMembersByQuery('', MemberStatus.SIGNED_UP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    members,
    allMembers,
    loading,
    fetchMembersByQuery,
  };
};
