import React, { FC, ReactNode, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { trackEvent } from '../../extra/sharedMethods';
import { BotMinimal } from '../../models/Bots';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useUserSafe } from '../../scripts/hooks';
import { useBotsMinimal } from '../../scripts/hooks/botsMinimal';
import { MemberStatus, OrgMember } from '../../scripts/models/org-member';
import { Loading } from '../controls/Loading/Loading';
import { RenderSuggestion } from './QAMentionsSuggestions';

interface QAMentionsInputProps {
  autofocus: boolean;
  showPopupFromBottom?: boolean;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  onKeyDown: (event: React.KeyboardEvent) => void;
  handleTextInputChange: (newValue: string) => void;
  dynamicPlaceholder: string;
  searchQuery: string;
  mentions?: string;
  setMentions: (mentions?: string) => void;
  setUserMentions: React.Dispatch<React.SetStateAction<string[]>>;
  allBots: BotMinimal[];
  allMembers: OrgMember[];
  fetchMembersByQuery: (
    q: string,
    status: MemberStatus
  ) => Promise<OrgMember[]>;
  allBotsLoading: boolean;
  allMembersLoading: boolean;
  shouldShowDashworksBot: boolean;
  selectedBotMention?: string;
  selectedUserMentions: string[];
}

interface MentionData {
  id: string;
  display: string;
}

export const DASHWORKS_BOT_ID = 'internal-dashworks-bot';
const DASHWORKS_BOT = {
  id: DASHWORKS_BOT_ID,
  bot_name: 'Dashworks',
};

const getAllMentions = (
  query: string,
  orgMembers: OrgMember[],
  shouldShowDashworksBot: boolean,
  selectedUserMentions: string[],
  userSub: string,
  mentionBots?: BotMinimal[],
  selectedBotMention?: string
  // eslint-disable-next-line max-params
): { members: MentionData[]; bots: MentionData[] } => {
  const dashworksBot = shouldShowDashworksBot ? [DASHWORKS_BOT] : [];

  const botsMentionsData: MentionData[] = (
    mentionBots ? [...dashworksBot, ...mentionBots] : []
  )
    .map((bot) => ({
      id: bot.id,
      display: bot.bot_name,
    }))
    .filter((mention) =>
      mention.display.toLowerCase().includes(query.toLowerCase())
    )
    .slice(0, 5);

  const membersMentionsData: MentionData[] = orgMembers
    .filter((member) => member.status === MemberStatus.SIGNED_UP)
    .map((member) => ({
      id: member.user_id,
      display: member.name ?? member.email,
    }))
    .filter((mention) =>
      mention.display.toLowerCase().includes(query.toLowerCase())
    )
    .filter(
      (mention) =>
        !selectedUserMentions.includes(mention.id) && mention.id !== userSub
    )
    .slice(0, 5);

  const isBotSelectionAllowed = selectedUserMentions.length === 0;
  const isMemberSelectionAllowed = !selectedBotMention;

  return {
    members: isMemberSelectionAllowed ? membersMentionsData : [],
    bots: isBotSelectionAllowed ? botsMentionsData : [],
  };
};

export const QAMentionsInput: FC<QAMentionsInputProps> = ({
  autofocus,
  showPopupFromBottom = false,
  inputRef,
  onKeyDown,
  handleTextInputChange,
  dynamicPlaceholder,
  searchQuery,
  mentions,
  setMentions,
  allBots,
  allMembers,
  allBotsLoading,
  allMembersLoading,
  fetchMembersByQuery,
  shouldShowDashworksBot,
  setUserMentions,
  selectedBotMention,
  selectedUserMentions,
}) => {
  const [firstBotId, setFirstBotId] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const userSub = useUserSafe((u) => u.sub);

  const { fetchBotsByQuery } = useBotsMinimal();

  return (
    <MentionsInput
      allowSpaceInQuery
      allowSuggestionsAboveCursor
      autoFocus={autofocus}
      className="bg-transparent block text-base flex-1 border-0 rounded-lg resize-none outline-none w-full break-words"
      // eslint-disable-next-line react/no-unstable-nested-components
      customSuggestionsContainer={(children: ReactNode): ReactNode => (
        <div
          className={`bg-white border border-solid border-gray-30 rounded-md w-72 max-h-[300px] overflow-y-auto !cursor absolute left-2 ${
            showPopupFromBottom ? 'top-0' : 'bottom-0'
          }`}
        >
          {isLoading ? <Loading /> : children}
        </div>
      )}
      forceSuggestionsAboveCursor
      inputRef={inputRef}
      onChange={(_, newValue) => {
        handleTextInputChange(newValue);
        if (mentions && !newValue.includes(mentions)) {
          setMentions(undefined);
        }

        setUserMentions((prev) =>
          prev.filter((mention) => newValue.includes(mention))
        );
      }}
      onKeyDown={onKeyDown}
      placeholder={dynamicPlaceholder}
      style={{
        control: {
          border: 'none',
          outline: 'none',
        },
        highlighter: {
          overflow: 'hidden',
          maxHeight: '250px',
        },
        input: {
          margin: 0,
          border: 'none',
          outline: 'none',
          overflow: 'auto',
          maxHeight: '250px',
          scrollbarWidth: 'none',
        },
        suggestions: {
          zIndex: 1000,
        },
      }}
      tabIndex={1}
      value={searchQuery}
    >
      <Mention
        appendSpaceOnAdd
        className="mentions__suggestions"
        data={async (q, cb) => {
          if (allBotsLoading && allMembersLoading) {
            cb([{ id: 'loading', display: 'Loading...' }]);
            setIsLoading(true);
          }

          let allMentions = getAllMentions(
            q,
            allMembers,
            shouldShowDashworksBot,
            selectedUserMentions,
            userSub,
            allBots,
            selectedBotMention
          );

          if (allMentions.members.length > 0 || allMentions.bots.length > 0) {
            cb([...allMentions.members, ...allMentions.bots]);
            setFirstBotId(allMentions.bots[0]?.id);
            setIsLoading(false);
          }

          const rMembers = await fetchMembersByQuery(q, MemberStatus.SIGNED_UP);
          const rBots = await fetchBotsByQuery(q);

          allMentions = getAllMentions(
            q,
            rMembers,
            shouldShowDashworksBot,
            selectedUserMentions,
            userSub,
            rBots,
            selectedBotMention
          );

          cb([...allMentions.members, ...allMentions.bots]);
          setFirstBotId(allMentions.bots[0]?.id);
          setIsLoading(false);
        }}
        displayTransform={(_, display) => ` @${display} `}
        markup="@{{__display__}}{{__id__}}"
        onAdd={(id) => {
          if (id === DASHWORKS_BOT_ID) {
            setMentions(String(id));
            trackEvent(AnalyticsEvent.MentionedDashworksBot, { bot_id: id });
          }

          const bot = allBots.find((b) => b.id === id);
          if (bot) {
            setMentions(String(id));
            trackEvent(AnalyticsEvent.MentionedBot, { bot_id: id });
          } else {
            setUserMentions((prev) => [...prev, String(id)]);
            trackEvent(AnalyticsEvent.MentionedUser, { user_id: id });
          }
        }}
        renderSuggestion={(
          entry,
          _search,
          highlightedDisplay,
          _index,
          focused
        ) => {
          return (
            <RenderSuggestion
              allBots={allBots}
              allMembers={allMembers}
              entry={entry}
              firstBotId={firstBotId}
              focused={focused}
              highlightedDisplay={highlightedDisplay}
              index={_index}
            />
          );
        }}
        style={{
          backgroundColor: '#C9FFFF',
          borderRadius: '6px',
          marginLeft: '2px',
          paddingBottom: '4px',
        }}
        trigger="@"
      />
    </MentionsInput>
  );
};
