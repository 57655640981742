export enum MarketingMaterialType {
  ORGS,
  MEMBERS,
}

export interface JoinedOrgs {
  name: string;
  logo: string;
}

export interface JoinedMembers {
  name: string;
  icon: string;
  email: string;
}
