import React, { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PaywallModal } from '../../components/admin/EnterprisePaywall/EnterprisePaywall';
import { QAFeedbackModal } from '../../components/jit-qa/QAFeedbackModal';
import { QAHeaderConnectApps } from '../../components/jit-qa/QAHeaderConnectApps';
import { QAMessageView } from '../../components/jit-qa/QAMessageView';
import { QAPageAnswerView } from '../../components/jit-qa/QAPageView';
import { QAStreamingMessage } from '../../components/jit-qa/QAStreamingMessage';
import { QAReferencesSidecar } from '../../components/jit-qa/references/QAReferencesSidecar/QAReferencesSidecar';
import { QATextInputView } from '../../components/jit-qa/textInputBox/QATextInputView';
import { QATopicDivider } from '../../components/jit-qa/topicDivider/QATopicDivider';
import { LoadingSplash } from '../../components/splash/LoadingSplash';
import { QAWorkflowTemplateModal } from '../../components/workflowModal/QAWorkflowTemplateModal';
import { SidebarRoutes } from '../../containers/SidebarContainer';
import { trackEvent } from '../../extra/sharedMethods';
import {
  DEFAULT_TOPIC_ACCESS,
  QAMessage,
  TopicAuthor,
} from '../../models/QAmodels';
import { SocialAppUsers } from '../../models/User';
import { useQAController } from '../../scripts/QAController';
import { invokeFastApi } from '../../scripts/apis/fastapi';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useBoolState, useUserSafe } from '../../scripts/hooks';
import { useBots } from '../../scripts/hooks/bots';
import { logDebug } from '../../scripts/utils';
import { MarketingMaterial } from './MarketingMaterial';
import { MarketingMaterialType } from './types';

export const TopicPage: FC = () => {
  const { id, msgId } = useParams<{ id: string; msgId?: string }>();

  const [author, setAuthor] = React.useState<TopicAuthor>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const history = useHistory();
  const user = useUserSafe();

  const qaController = useQAController();
  const { bots } = useBots();

  const messages = qaController.useCurrentTopicMessages();

  const [openPaywallModal, setOpenPaywallModal, setNotOpenPaywallModal] =
    useBoolState(false);

  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      try {
        const {
          author: responseAuthor,
          messages: responseMessages,
          topic_files: responseTopicFiles,
        } = await invokeFastApi<{
          author: TopicAuthor;
          messages: QAMessage[];
          topic_files: string[];
        }>({
          path: `/topics/topicById/${id}`,
          method: 'GET',
        });

        qaController.setCurrentTopicMessages(responseMessages);
        qaController.setTopicFiles(id, responseTopicFiles);
        qaController.markTopicAsRead(id);
        setAuthor(responseAuthor);
      } catch (error) {
        logDebug(error);
        history.push(SidebarRoutes.JitQA);
      }

      setLoading(false);
    };

    fetchMessages();
    qaController.setIsNewTopic(false);
  }, [id, history, qaController]);

  useEffect(() => {
    if (!author) return;
    if (user.email !== author.email) {
      trackEvent(AnalyticsEvent.SharableLinkViewed);
    }
  }, [author, user]);

  const { topicTitle, topicTimestamp, topicAccess } = useMemo(() => {
    if (messages[0] && messages[0].conversation_id === id) {
      return {
        topicTitle: messages[0].topic_title,
        topicTimestamp: messages[0].conversation_timestamp,
        topicAccess: messages[0].topicAccess,
      };
    }

    return {
      topicTitle: '',
      topicTimestamp: 0,
      topicAccess: {
        isMultiplayer: false,
        isOrgWide: false,
        isPublic: false,
      },
    };
  }, [messages, id]);

  useEffect(() => {
    if (!topicAccess || !author) return;
    if (author.email === user.email) return;

    if (topicAccess.isPublic) {
      trackEvent(AnalyticsEvent.ViewedPublicTopic, { topicId: id });
    }

    if (topicAccess.isOrgWide) {
      trackEvent(AnalyticsEvent.ViewedOrgWideTopic, { topicId: id });
    }
  }, [topicAccess, author, user, id]);

  if (loading) {
    return (
      <div className="flex h-screen">
        <LoadingSplash />
      </div>
    );
  }

  const isTopicEditable = topicAccess?.isMultiplayer
    ? true
    : author?.email === user.email;

  return (
    <div className="flex h-screen">
      <div className="flex flex-1 flex-col h-full">
        <div className="flex-grow overflow-auto scrollbar-track-white scrollbar-thumb-gray-30 scrollbar-thin">
          <div className="sticky top-0 z-10 bg-white">
            <QATopicDivider
              conversationId={id}
              isDeletable={author?.email === user.email}
              isEditable={isTopicEditable}
              isNewTopic={false}
              topicAccess={topicAccess ?? DEFAULT_TOPIC_ACCESS}
              topicAuthor={author!}
              topicTimestamp={topicTimestamp}
              topicTitle={topicTitle}
            />
          </div>
          <div className="hidden lg:block">
            <QAHeaderConnectApps />
          </div>
          <div className="max-w-3xl mx-auto mb-8 mt-2 w-[100vw]">
            <div>
              {messages.map((message) => (
                <QAMessageView
                  bots={bots}
                  isFocused={message.message_id === msgId}
                  isViewOnly={!isTopicEditable}
                  key={message.row_id}
                  qaMessage={message}
                  sharableConversation={messages}
                  showAllSources={message.author?.email === user.email}
                />
              ))}
              <QAStreamingMessage
                bots={bots}
                showAllSources={author?.email === user.email}
                topic_id={id}
              />
            </div>
          </div>
          {!isTopicEditable && <TeamMarketingMaterial />}
        </div>
        {isTopicEditable && (
          <div className="sticky bottom-0 bg-white">
            <QATextInputView
              autofocus={false}
              continueConversationId={id}
              setOpenPaywallModal={setOpenPaywallModal}
            />
          </div>
        )}
        <QAPageAnswerView />
        <QAWorkflowTemplateModal setOpenPaywallModal={setOpenPaywallModal} />
        <QAFeedbackModal sharableConversation={messages} />
      </div>
      <QAReferencesSidecar />

      {openPaywallModal && (
        <PaywallModal allowUpgrade closeModal={setNotOpenPaywallModal} />
      )}
    </div>
  );
};

const TeamMarketingMaterial = () => {
  const user = useUserSafe();

  const teamMates = useMemo(() => {
    const seen = new Set();

    return user.orgByOrgId.appPreferences.recommended_apps_list?.reduce(
      (acc: SocialAppUsers[], app) => {
        if (app.social_info_list.length > 0) {
          for (const socialInfo of app.social_info_list) {
            if (acc.length > 5) continue;
            if (seen.has(socialInfo.email)) continue;
            seen.add(socialInfo.email);
            acc.push(socialInfo);
          }
        }

        return acc;
      },
      []
    );
  }, [user]);

  return (
    <MarketingMaterial
      data={teamMates ?? []}
      type={MarketingMaterialType.MEMBERS}
    />
  );
};
