/* eslint-disable max-lines */
import { createContext, useContext } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { QAFile } from '../components/jit-qa/files/QAFilesContext';
import { SendQueryArgs } from '../components/jit-qa/textInputBox/QATextInputBox';
import { onMessageAddedFn } from '../components/realtimeChat/RealtimeChatContext';
import { EventEmitter, Listener } from '../emitter';
import { CreatorDetails } from '../models/Bots';
import { UploadedFile } from '../models/File';
import {
  AllReferencesSummary,
  ChatExtraData,
  ConversationMember,
  DEFAULT_TOPIC_ACCESS,
  MessageType,
  QAControllerState,
  QAMessage,
  QAPacketType,
  QAStages,
  QAStreamMessage,
  QATopic,
  QATopicAccess,
  QATopicAccessType,
  Reference,
  SetStageMessageData,
  SkillRetrieved,
  StageVariables,
  StaticAnswerType,
  TopicType,
} from '../models/QAmodels';
import { SupportedLlm } from '../models/User';
import { WorkflowModalTypes } from '../models/Workflows';
import { store } from '../redux/store';
import { logDebug, logError, uniqBy } from '../scripts/utils';
import { invokeFastApi } from './apis/fastapi';
import {
  QAWebSocketRequestHandler,
  StreamMessageBody,
} from './apis/qaWebSocketClient';
import { ConnectedApps } from './hooks/sortedInstantApps';
import { Answer } from './models/answers';
import { TimeoutError } from './timeout-watcher';
import { WatchedValue } from './value-watcher';

export const MAX_QUERY_LENGTH = 5000;

export interface GetTopics {
  page: number;
  query: string;
  fetchMore?: boolean;
  topicsType: TopicType;
  managedBotId?: string;
}

export interface QAControllerEventArgs {
  // question
  query?: string;
  query_id?: string;

  // answer
  response_id?: string;
  response_references?: Reference[];
  response_rating?: number;

  // references
  allReferencesSummary?: AllReferencesSummary;

  answerId: string;
  answerCreateModalTitle: string;
  answerCreatePrefillQuestion: string;
  isAuthor: boolean;
  isDefault: boolean;
  workflowModalType?: WorkflowModalTypes;
  workflowId?: string;
  workflowTemplate?: string;
  isBaseLLM?: boolean;
  skillFilters?: ConnectedApps[];
  fileFilters?: UploadedFile[];
  answerFilters?: Answer[];
  preferredLlm?: SupportedLlm | null;
}

type QAControllerEvents =
  | 'hideAppsNotifications'
  | 'saveWorkflow'
  | 'setQuery'
  | 'setQueryAndSources'
  | 'showAnswerDialog'
  | 'showFeedbackModal'
  | 'showReferencesSidecar'
  | 'showWorkflowModal'
  | 'stopGenerating';

export class QAController {
  private readonly streamingAnswerValue = new WatchedValue<
    QAMessage | undefined
  >(undefined);

  private readonly messagesValue = new WatchedValue<QAMessage[]>([]);
  private readonly currentTopicMessagesValue = new WatchedValue<QAMessage[]>(
    []
  );

  private readonly currentTopicId = new WatchedValue<string | null>(null);

  private readonly newlyAddedConversationMembers = new WatchedValue<
    ConversationMember[]
  >([]);

  private readonly topicListValue = new WatchedValue<{
    [key: string]: QATopic[];
    [TopicType.MY_TOPICS]: QATopic[];
    [TopicType.SHARED_WITH_ME]: QATopic[];
  }>({ [TopicType.MY_TOPICS]: [], [TopicType.SHARED_WITH_ME]: [] });

  private readonly stateValue = new WatchedValue<QAControllerState>({
    isFetchingMessages: false,
    isNewTopic: true,
    currentStage: QAStages.WAIT_USER_INPUT,
    hasMoreMessages: true,
    allTopicsLoaded: {
      [TopicType.MY_TOPICS]: false,
      [TopicType.SHARED_WITH_ME]: false,
    },
    topicFilesMap: {},
  });
  private readonly existingRowIds = new Set<string>();

  // eslint-disable-next-line unicorn/prefer-event-target
  private readonly eventEmitter = new EventEmitter<QAControllerEventArgs>();

  public getIsNewTopic(): { isNewTopic: boolean } {
    const { isNewTopic } = this.stateValue.getValue();
    return { isNewTopic };
  }

  public getTopicFilesMap(): {
    topicFilesMap: Record<string, string[]>;
  } {
    const { topicFilesMap } = this.stateValue.getValue();
    return { topicFilesMap };
  }

  public getTopicFiles(
    conversationId: string,
    topicFilesMap: Record<string, string[]>
  ): string[] {
    const topicFiles = topicFilesMap[conversationId] ?? [];
    return topicFiles;
  }

  public setTopicFiles(conversationId: string, files: string[]): void {
    const { topicFilesMap } = this.stateValue.getValue();
    this.stateValue.updateObject({
      topicFilesMap: { ...topicFilesMap, [conversationId]: files },
    });
  }

  public addToCurrentTopicFilesMap(
    conversationId: string,
    files: string[]
  ): void {
    const { topicFilesMap } = this.stateValue.getValue();
    const topicFiles = this.getTopicFiles(conversationId, topicFilesMap);
    this.stateValue.updateObject({
      topicFilesMap: {
        ...topicFilesMap,
        [conversationId]: [...topicFiles, ...files],
      },
    });
  }

  public setIsNewTopic(value: boolean): void {
    this.stateValue.updateObject({ isNewTopic: value });
  }

  public setCurrentTopicMessages(value: QAMessage[]): void {
    this.currentTopicMessagesValue.applyUpdate(() => value);
  }

  public setCurrentTopicId(topicId: string | null): void {
    this.currentTopicId.applyUpdate(() => topicId);
  }

  public removeTopic(conversation_id: string): void {
    this.messagesValue.applyUpdate((old) => {
      return old.filter((m) => m.conversation_id !== conversation_id);
    });

    this.topicListValue.applyUpdate((old) => {
      for (const key in old) {
        if (Array.isArray(old[key])) {
          old[key] = old[key]!.filter((m) => m.topicId !== conversation_id);
        }
      }

      return { ...old };
    });
  }

  public getLatestQAMessage(
    messages: QAMessage[],
    currentTopicMessagesValue: QAMessage[],
    conversation_id?: string
  ): QAMessage | null {
    const message = messages.find((m) => m.conversation_id === conversation_id);

    if (message) return message;

    const currentTopicMessage = currentTopicMessagesValue.find(
      (m) => m.conversation_id === conversation_id
    );

    if (currentTopicMessage) return currentTopicMessage;

    return null;
  }

  public makeAllConversationsNonPublic(): void {
    this.messagesValue.applyUpdate((old) => {
      return old.map((m) => {
        return { ...m, topicAccess: { ...m.topicAccess, isPublic: false } };
      });
    });

    this.currentTopicMessagesValue.applyUpdate((old) => {
      return old.map((m) => {
        return { ...m, topicAccess: { ...m.topicAccess, isPublic: false } };
      });
    });
  }

  public updateConversationDetails(
    conversation_id: string,
    details: {
      topic_title?: string;
      topicAccess?: QATopicAccess;
    }
  ): void {
    this.messagesValue.applyUpdate((old) => {
      return old.map((m) => {
        if (m.conversation_id === conversation_id) {
          return { ...m, ...details };
        }

        return m;
      });
    });

    this.currentTopicMessagesValue.applyUpdate((old) => {
      return old.map((m) => {
        if (m.conversation_id === conversation_id) {
          return { ...m, ...details };
        }

        return m;
      });
    });

    if (details.topic_title) {
      this.topicListValue.applyUpdate((old) => {
        for (const key in old) {
          if (Array.isArray(old[key])) {
            old[key] = old[key]!.map((m) => {
              if (m.topicId === conversation_id) {
                return { ...m, topicTitle: details.topic_title ?? '' };
              }

              return m;
            });
          }
        }

        return { ...old };
      });
    }
  }

  public async updateConversationVisibility(
    conversationId: string,
    accessType: QATopicAccessType,
    topicAccess: QATopicAccess
  ): Promise<void> {
    this.updateConversationDetails(conversationId, {
      topicAccess,
    });

    let body = {};

    switch (accessType) {
      case QATopicAccessType.PUBLIC:
        body = {
          is_public: true,
        };

        break;
      case QATopicAccessType.ORG:
        body = {
          visibility: 'ORG',
        };

        break;
      default:
        body = {
          visibility: 'PRIVATE',
        };

        break;
    }

    try {
      await invokeFastApi({
        path: `/topics/topic/${conversationId}`,
        method: 'PATCH',
        body,
      });
    } catch (error) {
      logError(error);
    }
  }

  public updateMessageTopicTitle(
    conversation_id: string,
    topic_title: string
  ): void {
    let conversationTimestamp = Date.now();

    this.messagesValue.applyUpdate((old) => {
      return old.map((m) => {
        if (m.conversation_id === conversation_id) {
          conversationTimestamp = m.conversation_timestamp;
          return { ...m, topic_title };
        }

        return m;
      });
    });

    this.currentTopicMessagesValue.applyUpdate((old) => {
      return old.map((m) => {
        if (m.conversation_id === conversation_id) {
          return { ...m, topic_title };
        }

        return m;
      });
    });

    const allTopics = this.topicListValue.getValue();
    const isInTopics = Object.values(allTopics).some((topics) =>
      topics.some((t) => t.topicId === conversation_id)
    );

    if (isInTopics) {
      this.topicListValue.applyUpdate((old) => {
        for (const key in old) {
          if (Array.isArray(old[key])) {
            old[key] = old[key]!.map((m) => {
              if (m.topicId === conversation_id) {
                return { ...m, topicTitle: topic_title };
              }

              return m;
            });
          }
        }

        return { ...old };
      });
    } else {
      this.topicListValue.applyUpdate((old) => {
        return {
          ...old,
          [TopicType.MY_TOPICS]: [
            {
              topicId: conversation_id,
              topicTitle: topic_title,
              createdAt: conversationTimestamp,
            },
            ...old[TopicType.MY_TOPICS],
          ],
        };
      });
    }
  }

  public setNewlyAddedConversationMembers(value: ConversationMember[]): void {
    this.newlyAddedConversationMembers.applyUpdate((prev) => [
      ...prev,
      ...value,
    ]);
  }

  public getUserMessageForAssistantAnswer(
    assistantRowId: string,
    sharableConversation?: QAMessage[]
  ): QAMessage | undefined {
    const messages = sharableConversation ?? this.messagesValue.getValue();

    const assistantMessageIndex = messages.findIndex(
      (m) => m.row_id === assistantRowId
    );

    if (assistantMessageIndex === -1) {
      return;
    }

    const messagesTillAssistantMessage = messages
      .slice(0, assistantMessageIndex)
      .reverse();

    return messagesTillAssistantMessage.find((m) => m.sender === 'USER');
  }

  public async fetchHistoryMessages(reset = false): Promise<void> {
    if (reset) {
      logDebug('resetting chat messages');
      this.messagesValue.applyUpdate(() => []);
      this.stateValue.updateObject({ hasMoreMessages: true });
      this.existingRowIds.clear();
    }

    const last_fetched_ts =
      this.messagesValue.getValue()[0]?.tsSentAt ?? undefined;

    let { hasMoreMessages } = this.stateValue.getValue();
    try {
      logDebug('Fetching messages, last_fetched_ts', last_fetched_ts);
      this.stateValue.updateObject({
        isFetchingMessages: true,
      });

      const { messages: responseMessages, conversationFilesMap } =
        await this._apiFetchHistoryMessages(last_fetched_ts);

      const newMessages = responseMessages
        .filter((m) => !this.existingRowIds.has(m.row_id))
        .sort((a, b) => a.conversation_timestamp - b.conversation_timestamp);

      logDebug(
        'fetched message count',
        responseMessages.length,
        'non dup messages length',
        newMessages.length
      );

      for (const [conversationId, files] of Object.entries(
        conversationFilesMap
      )) {
        this.setTopicFiles(conversationId, files);
      }

      hasMoreMessages = newMessages.length > 0;
      this.messagesValue.applyUpdate((old) => newMessages.concat(old));
      for (const m of newMessages) {
        this.existingRowIds.add(m.row_id);
      }
    } finally {
      this.stateValue.updateObject({
        isFetchingMessages: false,
        hasMoreMessages,
      });
    }
  }

  public insertTopics(
    topics: QATopic[],
    topicsType: TopicType,
    managedBotId?: string
  ): void {
    const key =
      topicsType === TopicType.MANAGED_BOTS && managedBotId
        ? managedBotId
        : topicsType;

    this.topicListValue.applyUpdate((old) => {
      return {
        ...old,
        [key]: uniqBy(
          [
            ...(old[key] ?? []).filter(
              (topic) => !topics.some((t) => t.topicId !== topic.topicId)
            ),
            ...topics.filter((topic) => !!topic.topicTitle),
          ],
          'topicId'
        ).sort((a, b) => b.createdAt - a.createdAt),
      };
    });
  }

  public hasUnread(topicsType: TopicType, managedBotId?: string): boolean {
    const key =
      topicsType === TopicType.MANAGED_BOTS && managedBotId
        ? managedBotId
        : topicsType;

    const allTopics = this.topicListValue.getValue()[key];

    if (!allTopics) {
      return false;
    }

    return allTopics.some((item) => item.unreadCount);
  }

  public async fetchTopics({
    page,
    query,
    topicsType,
    managedBotId,
    fetchMore = false,
  }: GetTopics): Promise<void> {
    const { topics } = await invokeFastApi<{ topics: QATopic[] }>({
      path: '/topics',
      method: 'GET',
      queryParams: {
        page: page.toString(),
        query,
        topic_type: topicsType,
        managed_bot_id: managedBotId,
      },
    });

    const key =
      topicsType === TopicType.MANAGED_BOTS && managedBotId
        ? managedBotId
        : topicsType;

    this.topicListValue.applyUpdate((old) => ({
      ...old,
      [key]: fetchMore ? [...(old[key] ?? []), ...topics] : topics,
    }));

    const { allTopicsLoaded } = this.stateValue.getValue();

    this.stateValue.updateObject({
      allTopicsLoaded: {
        ...allTopicsLoaded,
        [key]: topics.length === 0,
      },
    });
  }

  public async markTopicAsRead(
    topicId: string,
    skipApiCall = false
  ): Promise<void> {
    this.topicListValue.applyUpdate((old) => {
      for (const key in old) {
        if (Array.isArray(old[key])) {
          old[key] = old[key]!.map((topic) =>
            topic.topicId === topicId ? { ...topic, unreadCount: 0 } : topic
          );
        }
      }

      return { ...old };
    });

    if (skipApiCall) return;

    try {
      await invokeFastApi({
        path: `/topics/topic/${topicId}/mark_as_read`,
        method: 'PATCH',
      });
    } catch (error) {
      logError(error);
    }
  }

  public async markAllMessagesAsRead(): Promise<void> {
    this.topicListValue.applyUpdate((old) => {
      for (const key in old) {
        if (Array.isArray(old[key])) {
          old[key] = old[key]!.map((topic) => ({ ...topic, unreadCount: 0 }));
        }
      }

      return { ...old };
    });

    try {
      await invokeFastApi({
        path: `/messages/mark_all_as_read`,
        method: 'PATCH',
      });
    } catch (error) {
      logError(error);
    }
  }

  public async sendNewMessage({
    queryText,
    conversation_id,
    sources,
    llm_preference,
    continueTopic,
    bot_id,
    uploaded_conversation_files,
    author,
    topicAccess,
    onMessageAdded,
    bot_icon,
    bot_name,
  }: SendQueryArgs): Promise<void> {
    const row_id = uuidV4().replace(/-/g, '');
    const message_id = uuidV4().replace(/-/g, '');

    const { isNewTopic } = this.getIsNewTopic();
    const sanitizedQueryText = this._sanitizeQueryText(queryText, bot_id);

    const userMessage = [
      this.buildNewMessage({
        text: sanitizedQueryText,
        sender: 'USER',
        conversation_id,
        row_id,
        message_id,
        bot_id,
        files: uploaded_conversation_files,
        author,
        topicAccess,
        bot_icon,
        bot_name,
      }),
    ];

    const addToMessages = this.addQAMessageToTopic(
      conversation_id,
      userMessage,
      continueTopic
    );

    onMessageAdded(userMessage[0]!);

    this.stateValue.updateObject({
      currentStage: QAStages.SENDING_REQUEST,
    });

    this.streamingAnswerValue.applyUpdate(() =>
      this.buildNewMessage({
        text: this.getTextMessageForStage(QAStages.SENDING_REQUEST) ?? '',
        sender: 'ASSISTANT',
        conversation_id,
        message_id,
        bot_id,
        isPreFinalGeneration: true,
        progressBar: 5,
        author,
      })
    );

    const body: StreamMessageBody = {
      query: sanitizedQueryText,
      row_id,
      message_id,
      conversation_id,
      isNewTopic,
      sources,
      llm_preference,
      bot_id,
      tz_offset: new Date().getTimezoneOffset(),
      uploaded_conversation_file_ids: (uploaded_conversation_files ?? []).map(
        (file) => file.id
      ),
    };

    const { tokens } = store.getState();
    const idToken = tokens?.loginTokens?.id_token;
    if (!idToken) {
      throw new Error('Tokens not found');
    }

    const websocket = new QAWebSocketRequestHandler<QAStreamMessage>({
      onMessage: (message: QAStreamMessage) => {
        this._processStreamMessage(
          message,
          conversation_id,
          message_id,
          author,
          bot_id
        );
      },
      onClose: () => {
        websocket.close();
        this._finalizeStreamMessage(
          conversation_id,
          message_id,
          onMessageAdded,
          topicAccess,
          bot_id,
          addToMessages
        );
      },
      onError: (e) => {
        websocket.close();
        logError(e);
        this._onError(
          getStreamErrorMessage(e),
          conversation_id,
          message_id,
          e,
          onMessageAdded,
          topicAccess,
          author,
          bot_id
        );
      },
    });

    await websocket.sendMessage({
      authorization: `Bearer ${idToken}`,
      type: 'AUTH',
    });

    await websocket.sendMessage({ ...body, type: 'QUERY' });

    this.addToCurrentTopicFilesMap(
      conversation_id,
      uploaded_conversation_files?.map((file) => file.id) ?? []
    );

    this.listenEvent('stopGenerating', () => {
      this._stopGeneratingMessage(
        message_id,
        conversation_id,
        websocket,
        onMessageAdded,
        topicAccess,
        author,
        addToMessages,
        bot_id
      );
    });
  }

  public addMessageToTopic({
    queryText,
    conversation_id,
    continueTopic,
    author,
    sender,
    bot_id,
    topicAccess,
  }: {
    queryText: string;
    conversation_id?: string;
    continueTopic: boolean;
    author?: CreatorDetails;
    sender?: 'ASSISTANT' | 'USER';
    bot_id?: string;
    topicAccess: QATopicAccess;
  }): QAMessage {
    if (!conversation_id) {
      conversation_id = uuidV4().replace(/-/g, '');
    }

    const row_id = uuidV4().replace(/-/g, '');
    const message_id = uuidV4().replace(/-/g, '');

    const userMessage = [
      this.buildNewMessage({
        text: queryText,
        sender: sender ?? 'USER',
        conversation_id,
        row_id,
        message_id,
        author,
        topicAccess,
        bot_id,
      }),
    ];

    this.addQAMessageToTopic(conversation_id, userMessage, continueTopic);

    return userMessage[0]!;
  }

  public getTextMessageForStage(stage: QAStages): string | undefined {
    switch (stage) {
      case QAStages.WAIT_USER_INPUT: {
        return '';
      }

      case QAStages.SENDING_REQUEST: {
        return 'Understanding question...';
      }

      case QAStages.BUILDING_PROMPT: {
        return 'Building prompt...';
      }

      case QAStages.GATHERING_DATA: {
        return 'Gathering Data...';
      }

      case QAStages.STREAMING_ANSWER: {
        return 'Answering...';
      }
    }
  }

  public destruct(): void {
    // unbind all event listeners here
    this.eventEmitter.removeAllListeners();
  }

  public off(
    eventName: QAControllerEvents,
    listener: Listener<Partial<QAControllerEventArgs>>
  ): void {
    this.eventEmitter.off(eventName, listener);
  }

  public useMessages(): QAMessage[] {
    return this.messagesValue.useHook();
  }

  public useCurrentTopicMessages(): QAMessage[] {
    return this.currentTopicMessagesValue.useHook();
  }

  public useCurrentTopicId(): string | null {
    return this.currentTopicId.useHook();
  }

  public useNewlyAddedConversationMembers(): ConversationMember[] {
    return this.newlyAddedConversationMembers.useHook();
  }

  public useTopics(): {
    [key: string]: QATopic[];
    [TopicType.MY_TOPICS]: QATopic[];
    [TopicType.SHARED_WITH_ME]: QATopic[];
  } {
    return this.topicListValue.useHook();
  }

  public useProgressStage(): QAControllerState {
    return this.stateValue.useHook();
  }

  public useStreamingAnswer(): QAMessage | undefined {
    return this.streamingAnswerValue.useHook();
  }

  public listenEvent(
    eventName: QAControllerEvents,
    listener: Listener<Partial<QAControllerEventArgs>>
  ): void {
    this.eventEmitter.on(eventName, listener);
  }

  public triggerEvent(
    eventName: QAControllerEvents,
    eventArgs: Partial<QAControllerEventArgs>
  ): void {
    this.eventEmitter.emit(eventName, eventArgs);
  }

  public addQAMessageToTopic(
    conversation_id: string,
    userMessage: QAMessage[],

    continueTopic?: boolean
  ): boolean {
    const messages = this.messagesValue.getValue();
    const addToMessages =
      messages.some((m) => m.conversation_id === conversation_id) ||
      !continueTopic;

    if (addToMessages) {
      this.messagesValue.applyUpdate((old) => old.concat(userMessage));
    }

    this.currentTopicMessagesValue.applyUpdate((old) =>
      old.concat(userMessage)
    );

    return addToMessages;
  }

  private _sanitizeQueryText(queryText: string, bot_id?: string): string {
    if (bot_id) {
      return queryText.replace(/@{{(.*?)}}{{[^}]+}}/, '@$1');
    }

    return queryText;
  }

  private buildNewMessage({
    text,
    sender,
    conversation_id,
    row_id,
    message_id,
    bot_id,
    messageType,
    extra_data,
    isPreFinalGeneration = false,
    progressBar,
    files,
    author,
    topicAccess,
    bot_name,
    bot_icon,
  }: {
    text: string;
    sender: 'ASSISTANT' | 'USER';
    conversation_id: string;
    row_id?: string;
    message_id?: string;
    bot_id?: string;
    messageType?: MessageType;
    extra_data?: ChatExtraData;
    isPreFinalGeneration?: boolean;
    progressBar?: number;
    files?: QAFile[];
    author?: CreatorDetails;
    topicAccess?: QATopicAccess;
    bot_name?: string;
    bot_icon?: string;
  }): QAMessage {
    return {
      row_id: row_id ?? uuidV4().replace(/-/g, ''),
      message_id: message_id ?? uuidV4().replace(/-/g, ''),
      conversation_id,
      bot_id,
      conversation_timestamp: Date.now(),
      extraData: extra_data,
      sender,
      tsSentAt: Date.now(),
      messageText: text,
      messageEncoding: 'PLAIN_TEXT',
      isPreFinalGeneration,
      progressBar,
      references: [],
      allReferencesSummary: { app_references_count: [] },
      relatedSearches: [],
      debugLogs: { doc_data: {}, stages: [] },
      messageType,
      topic_title: '',
      files,
      author,
      topicAccess: topicAccess ?? DEFAULT_TOPIC_ACCESS,
      bot_icon,
      bot_name,
    };
  }

  // eslint-disable-next-line max-params
  private async _stopGeneratingMessage(
    message_id: string,
    conversation_id: string,
    websocket: QAWebSocketRequestHandler<QAStreamMessage>,
    onMessageAdded: onMessageAddedFn,
    topicAccess: QATopicAccess,
    author?: CreatorDetails,
    addToMessages = true,
    bot_id?: string
  ) {
    await websocket.sendMessage({ type: 'STOP_GENERATION' });
    websocket.close();
    if (this.stateValue.getValue().currentStage === QAStages.STREAMING_ANSWER) {
      this._finalizeStreamMessage(
        conversation_id,
        message_id,
        onMessageAdded,
        topicAccess,
        bot_id,
        addToMessages
      );

      return;
    }

    this.streamingAnswerValue.applyUpdate(() => {
      return this.buildNewMessage({
        text: 'Your request has been stopped. Feel free to ask another question at any time!',
        sender: 'ASSISTANT',
        conversation_id,
        message_id,
        bot_id,
        extra_data: { staticAnswerType: StaticAnswerType.STOPPED_GENERATION },
        author,
      });
    });

    this._finalizeStreamMessage(
      conversation_id,
      message_id,
      onMessageAdded,
      topicAccess,
      bot_id
    );
  }

  private _finalizeStreamMessage(
    conversation_id: string,
    message_id: string,
    onMessageAdded: onMessageAddedFn,
    topicAccess: QATopicAccess,
    bot_id?: string,
    addToMessages = true
  ) {
    logDebug('_finalizeStreamMessage called');
    this.eventEmitter.removeAllListenersForEvent('stopGenerating');
    const message = this.streamingAnswerValue.getValue();
    if (message) {
      message.conversation_id = conversation_id;
      message.message_id = message_id;
      message.bot_id = bot_id;
      message.isPreFinalGeneration = false;
      message.topicAccess = topicAccess;

      if (addToMessages) {
        this.messagesValue.applyUpdate((old) => old.concat([message]));
      }

      const allTopics = this.topicListValue.getValue();
      const isInTopics = Object.values(allTopics).some((topics) =>
        topics.some((t) => t.topicId === conversation_id)
      );

      if (!isInTopics && message.topic_title) {
        this.topicListValue.applyUpdate((old) => {
          return {
            ...old,
            [TopicType.MY_TOPICS]: [
              {
                topicId: conversation_id,
                topicTitle: message.topic_title!,
                createdAt: message.conversation_timestamp,
              },
              ...old[TopicType.MY_TOPICS],
            ],
          };
        });
      }

      this.currentTopicMessagesValue.applyUpdate((old) =>
        old.concat([message])
      );

      onMessageAdded(message);
    } else {
      logDebug('No current message to finalize');
    }

    this.streamingAnswerValue.applyUpdate(() => undefined);
    this.stateValue.applyUpdate((old) => {
      return {
        ...old,
        currentStage: QAStages.WAIT_USER_INPUT,
        stageTextMessage: undefined,
      };
    });
  }

  private _processStreamMessage(
    _message: QAStreamMessage,
    conversation_id: string,
    message_id: string,
    author?: CreatorDetails,
    bot_id?: string
  ) {
    switch (_message.type) {
      case QAPacketType.SET_STAGE: {
        const data = _message.data as SetStageMessageData;
        this.stateValue.applyUpdate((old) => {
          return {
            ...old,
            currentStage: data.stage,
            stageTextMessage: data.displayText,
          };
        });

        if (data.stage !== QAStages.WAIT_USER_INPUT) {
          this.streamingAnswerValue.applyUpdate(
            (old) => {
              if (old) {
                let { progressBar } = old;
                if (progressBar) {
                  progressBar += 15;
                } else {
                  progressBar = 50;
                }

                if (progressBar > 80) {
                  progressBar = 90;
                }

                return this.buildNewMessage({
                  text: data.displayText ?? 'Generating Answer...',
                  sender: 'ASSISTANT',
                  conversation_id,
                  message_id,
                  bot_id,
                  isPreFinalGeneration: true,
                  progressBar,
                  author,
                });
              }
            }
            // TODO: put appropriate fallback text for display text
          );
        }

        if (data.stage === QAStages.STREAMING_ANSWER) {
          this.streamingAnswerValue.applyUpdate(() => {
            return this.buildNewMessage({
              text: '',
              sender: 'ASSISTANT',
              conversation_id,
              message_id,
              bot_id,
              messageType: data.answerType,
              isPreFinalGeneration: false,
              progressBar: 100,
              author,
            });
          });
        }

        break;
      }

      case QAPacketType.CHUNK: {
        const messageText = _message.data as string;
        this.streamingAnswerValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              messageText: old.messageText.concat(messageText),
              skillRetrieved: undefined,
            };
          }

          return this.buildNewMessage({
            text: messageText,
            sender: 'ASSISTANT',
            conversation_id,
            message_id,
            bot_id,
            isPreFinalGeneration: false,
            author,
          });
        });

        break;
      }

      case QAPacketType.WEB_VIEW_URLS: {
        const { urls } = _message.data as { urls: Reference[] };
        this.streamingAnswerValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              references: old.references.concat(urls),
            };
          }
        });

        break;
      }

      case QAPacketType.ALL_WEB_VIEW_URLS_SUMMARY: {
        const { all_references_summary } = _message.data as {
          all_references_summary: AllReferencesSummary;
        };

        this.streamingAnswerValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              allReferencesSummary: all_references_summary,
            };
          }
        });

        break;
      }

      case QAPacketType.SKILL_RETRIEVED: {
        const skillsRetrieved = _message.data as SkillRetrieved;
        this.streamingAnswerValue.applyUpdate((old) => {
          if (old) {
            let { progressBar } = old;
            if (progressBar) {
              progressBar += 15;
            } else {
              progressBar = 50;
            }

            if (progressBar > 80) {
              progressBar = 90;
            }

            return {
              ...old,
              messageText: '',
              skillRetrieved: skillsRetrieved,
              progressBar,
            };
          }
        });

        break;
      }

      case QAPacketType.RELATED_SEARCHES: {
        const { suggested_queries } = _message.data as {
          suggested_queries: string[];
        };

        this.streamingAnswerValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              relatedSearches: old.relatedSearches.concat(suggested_queries),
            };
          }
        });

        break;
      }

      case QAPacketType.TOPIC_TITLE: {
        const { topic_title } = _message.data as {
          topic_title: string;
        };

        this.streamingAnswerValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              topic_title,
            };
          }
        });

        this.updateMessageTopicTitle(conversation_id, topic_title);

        break;
      }

      case QAPacketType.DEBUG_LOGS: {
        const {
          debug_logs: { stages, execution_time, doc_data },
        } = _message.data as {
          debug_logs: {
            stages: StageVariables[];
            execution_time?: number;
            doc_data: Record<string, never>;
          };
        };

        this.streamingAnswerValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              debugLogs: {
                stages: old.debugLogs?.stages.concat(stages) ?? [],
                execution_time,
                doc_data,
              },
            };
          }
        });

        break;
      }

      case QAPacketType.ROW_ID: {
        const { row_id } = _message.data as {
          row_id: string;
        };

        this.streamingAnswerValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              row_id,
            };
          }
        });

        break;
      }
    }
  }

  private async _apiFetchHistoryMessages(older_than_ts?: number): Promise<{
    messages: QAMessage[];
    conversationFilesMap: Record<string, string[]>;
  }> {
    const { history, conversation_files_map } = await invokeFastApi<{
      history: QAMessage[];
      conversation_files_map: Record<string, string[]>;
    }>({
      path: '/_chatHistory',
      method: 'GET',
      queryParams: {
        scroll_timestamp: older_than_ts?.toString(),
        api_version: '2',
      },
    });

    return { messages: history, conversationFilesMap: conversation_files_map };
  }

  // eslint-disable-next-line max-params
  private _onError(
    error: string,
    conversation_id: string,
    message_id: string,
    e: unknown,
    onMessageAdded: onMessageAddedFn,
    topicAccess: QATopicAccess,
    author?: CreatorDetails,
    bot_id?: string
  ) {
    logDebug('stream _onError', error);
    this.streamingAnswerValue.applyUpdate(() => {
      return this.buildNewMessage({
        text: error,
        sender: 'ASSISTANT',
        conversation_id,
        message_id,
        bot_id,
        isPreFinalGeneration: false,
        author,
        topicAccess,
      });
    });

    this._finalizeStreamMessage(
      conversation_id,
      message_id,
      onMessageAdded,
      topicAccess,
      bot_id
    );

    // refetch chat history for timeout error
    if (e instanceof TimeoutError) {
      this.fetchHistoryMessages(true);
    }
  }
}

const getStreamErrorMessage = (e: unknown): string => {
  if (e instanceof TimeoutError) {
    return 'A timeout occurred while reading response, please reload the page and try again';
  }

  return 'Error in streaming the response';
};

const context = createContext<QAController | undefined>(undefined);

export const useQAController = (): QAController => {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error('Attempted to use context outside of scope');
  }

  return ctx;
};

export const QAControllerProvider = context.Provider;
