import React from 'react';
import { useUserSafe } from '../../../scripts/hooks';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UISelect } from '../../controls/ui/UISelect/UISelect';
import { ROLE_OPTIONS } from '../MembersTableRow/MembersTableRow';
import { MemberCardContent } from './MemberCardContent';
import { UpdateMemberRoleFn } from './types';

interface InviteMemberItemProps {
  name?: string;
  email: string;
  icon: string;
  admin?: boolean;
  onRemove: (email: string) => void;
  updateMemberRole: UpdateMemberRoleFn;
}

export const InviteMemberItem: React.FC<InviteMemberItemProps> = ({
  name,
  email,
  icon,
  admin,
  onRemove,
  updateMemberRole,
}) => {
  const user = useUserSafe();

  return (
    <div className="flex gap-2 mb-4 items-center justify-between" key={email}>
      <div className="flex gap-2 items-center mb-0">
        <MemberCardContent email={email} icon={icon} name={name} />
      </div>
      <div className="flex items-center justify-center gap-3">
        {user.admin ? (
          <UISelect
            disableSelectionState
            dropdownWidth={120}
            items={ROLE_OPTIONS}
            onSelect={(value) => {
              updateMemberRole(email, value.value === 'admin');
            }}
            selectedValue={admin ? 'admin' : 'member'}
          />
        ) : (
          <span className="ownRole">{admin ? 'Admin' : 'Member'}</span>
        )}
        <UIIcon
          className="cursor-pointer"
          name="cross"
          onClick={() => {
            onRemove(email);
          }}
        />
      </div>
    </div>
  );
};
