import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { Feature, useFeatureAccess } from '../../hooks/subscriptionHooks';
import { SupportedLlm } from '../../models/User';
import { useFlag } from '../../scripts/hooks';
import { RadioList } from '../controls/RadioList/RadioList';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';

interface SupportedLlmData {
  displayName: string;
  icon: ReactNode;
  value: SupportedLlm;
  enterpriseOnly?: boolean;
}

export const llms: SupportedLlmData[] = [
  {
    displayName: 'ChatGPT-4o',
    icon: <UIIcon name="openai" size={16} type="apps" />,
    value: SupportedLlm.OPENAI_CHATGPT4_LATEST,
  },
  {
    displayName: 'GPT-4o',
    icon: <UIIcon name="openai" size={16} type="apps" />,
    value: SupportedLlm.OPENAI_GPT4_OMNI,
    enterpriseOnly: true,
  },
  {
    displayName: 'o1',
    icon: <UIIcon name="openai" size={16} type="apps" />,
    value: SupportedLlm.OPENAI_O1_LATEST,
    enterpriseOnly: true,
  },
  {
    displayName: 'o1-mini',
    icon: <UIIcon name="openai" size={16} type="apps" />,
    value: SupportedLlm.OPENAI_O1_MINI_LATEST,
    enterpriseOnly: true,
  },
  {
    displayName: 'o3-mini-high',
    icon: <UIIcon name="openai" size={16} type="apps" />,
    value: SupportedLlm.OPENAI_O3_MINI,
    enterpriseOnly: true,
  },
  {
    displayName: 'GPT-4 Turbo',
    icon: <UIIcon name="openai" size={16} type="apps" />,
    value: SupportedLlm.OPENAI_GPT4_TURBO,
    enterpriseOnly: true,
  },
  {
    displayName: 'Claude Sonnet',
    icon: <UIIcon name="anthropic" size={16} type="apps" />,
    value: SupportedLlm.ANTHROPIC_CLAUDE_SONNET,
    enterpriseOnly: true,
  },
  {
    displayName: 'Claude Opus',
    icon: <UIIcon name="anthropic" size={16} type="apps" />,
    value: SupportedLlm.ANTHROPIC_CLAUDE_OPUS,
    enterpriseOnly: true,
  },
  {
    displayName: 'Gemini 1.5',
    icon: <UIIcon name="google" size={16} type="apps" />,
    value: SupportedLlm.GOOGLE_GEMINI_1_5,
    enterpriseOnly: true,
  },
  {
    displayName: 'Gemini 2.0 Flash',
    icon: <UIIcon name="google" size={16} type="apps" />,
    value: SupportedLlm.GOOGLE_GEMINI_2_0_FLASH,
    enterpriseOnly: true,
  },
  {
    displayName: 'Gemini 2.0 Flash Thinking',
    icon: <UIIcon name="google" size={16} type="apps" />,
    value: SupportedLlm.GOOGLE_GEMINI_2_0_FLASH_THINKING,
    enterpriseOnly: true,
  },
  {
    displayName: 'Llama 70B',
    icon: <UIIcon name="meta" size={16} type="apps" />,
    value: SupportedLlm.META_LLAMA_70B,
    enterpriseOnly: true,
  },
  {
    displayName: 'R1',
    icon: <UIIcon name="deepseek" size={16} type="apps" />,
    value: SupportedLlm.DEEPSEEK_R1,
    enterpriseOnly: true,
  },
  {
    displayName: 'Grok (Beta)',
    icon: <UIIcon name="xai" size={16} type="apps" />,
    value: SupportedLlm.XAI_GROK_BETA,
    enterpriseOnly: true,
  },
  {
    displayName: 'Grok 2',
    icon: <UIIcon name="xai" size={16} type="apps" />,
    value: SupportedLlm.XAI_GROK_2,
    enterpriseOnly: true,
  },
];

interface LLMSelectListProps {
  defaultLlm: SupportedLlm | null;
  nonEnterpriseOnlyListItemClass?: string;
  handleLlmSelect(newLlm: SupportedLlm | null): void;
  setPaywallModalOpen(): void;
}

export const LLMSelectList: FC<LLMSelectListProps> = ({
  defaultLlm,
  nonEnterpriseOnlyListItemClass = '',
  handleLlmSelect,
  setPaywallModalOpen,
}) => {
  const hasAIModelSelectionFeature = useFeatureAccess(
    Feature.AI_MODEL_SELECTION
  );

  const [llm, setLlm] = useState<SupportedLlm | null>(defaultLlm);

  const openaiO1MiniLlmDisabled = useFlag('openaiO1MiniLlmDisabled');

  const openaiO1PreviewLlmDisabled: boolean = useFlag(
    'openaiO1PreviewLlmDisabled'
  );

  const openaiO3MiniLlmDisabled: boolean = useFlag('openaiO3MiniLlmDisabled');

  const openaiChatGpt4LatestLlmDisabled = useFlag(
    'openaiChatGpt4LatestLlmDisabled'
  );

  const anthropicClaudeSonnetLlmDisabled = useFlag(
    'anthropicClaudeSonnetLlmDisabled'
  );

  const anthropicClaudeOpusLlmDisabled = useFlag(
    'anthropicClaudeOpusLlmDisabled'
  );

  const deepseekR1LlmDisabled = useFlag('deepseekR1LlmDisabled');

  const googleGemini15LlmDisabled = useFlag('googleGemini15LlmDisabled');

  const googleGemini20FlashThinkingLlmDisabled = useFlag(
    'googleGemini20FlashThinkingLlmDisabled'
  );

  const googleGemini20FlashLlmDisabled = useFlag(
    'googleGemini20FlashLlmDisabled'
  );

  const metaLlama70BLlmDisabled = useFlag('metaLlama70BLlmDisabled');

  const xaiGrokBetaLlmDisabled = useFlag('xaiGroqBetaLlmDisabled');

  const xaiGrok2LlmDisabled = useFlag('xaiGroq2LlmDisabled');

  const finalLlms = useMemo(() => {
    let llmsCopy = llms;

    if (openaiO1MiniLlmDisabled) {
      llmsCopy = llmsCopy.filter(
        (l) => l.value !== SupportedLlm.OPENAI_O1_MINI_LATEST
      );
    }

    if (openaiO1PreviewLlmDisabled) {
      llmsCopy = llmsCopy.filter(
        (l) => l.value !== SupportedLlm.OPENAI_O1_LATEST
      );
    }

    if (openaiO3MiniLlmDisabled) {
      llmsCopy = llmsCopy.filter(
        (l) => l.value !== SupportedLlm.OPENAI_O3_MINI
      );
    }

    if (deepseekR1LlmDisabled) {
      llmsCopy = llmsCopy.filter((l) => l.value !== SupportedLlm.DEEPSEEK_R1);
    }

    if (openaiChatGpt4LatestLlmDisabled) {
      llmsCopy = llmsCopy.filter(
        (l) => l.value !== SupportedLlm.OPENAI_CHATGPT4_LATEST
      );
    }

    if (anthropicClaudeSonnetLlmDisabled) {
      llmsCopy = llmsCopy.filter(
        (l) => l.value !== SupportedLlm.ANTHROPIC_CLAUDE_SONNET
      );
    }

    if (anthropicClaudeOpusLlmDisabled) {
      llmsCopy = llmsCopy.filter(
        (l) => l.value !== SupportedLlm.ANTHROPIC_CLAUDE_OPUS
      );
    }

    if (googleGemini15LlmDisabled) {
      llmsCopy = llmsCopy.filter(
        (l) => l.value !== SupportedLlm.GOOGLE_GEMINI_1_5
      );
    }

    if (googleGemini20FlashLlmDisabled) {
      llmsCopy = llmsCopy.filter(
        (l) => l.value !== SupportedLlm.GOOGLE_GEMINI_2_0_FLASH
      );
    }

    if (googleGemini20FlashThinkingLlmDisabled) {
      llmsCopy = llmsCopy.filter(
        (l) => l.value !== SupportedLlm.GOOGLE_GEMINI_2_0_FLASH_THINKING
      );
    }

    if (metaLlama70BLlmDisabled) {
      llmsCopy = llmsCopy.filter(
        (l) => l.value !== SupportedLlm.META_LLAMA_70B
      );
    }

    if (xaiGrokBetaLlmDisabled) {
      llmsCopy = llmsCopy.filter((l) => l.value !== SupportedLlm.XAI_GROK_BETA);
    }

    if (xaiGrok2LlmDisabled) {
      llmsCopy = llmsCopy.filter((l) => l.value !== SupportedLlm.XAI_GROK_2);
    }

    return llmsCopy;
  }, [
    openaiO1MiniLlmDisabled,
    deepseekR1LlmDisabled,
    openaiO1PreviewLlmDisabled,
    openaiChatGpt4LatestLlmDisabled,
    openaiO3MiniLlmDisabled,
    anthropicClaudeSonnetLlmDisabled,
    anthropicClaudeOpusLlmDisabled,
    googleGemini15LlmDisabled,
    googleGemini20FlashLlmDisabled,
    googleGemini20FlashThinkingLlmDisabled,
    metaLlama70BLlmDisabled,
    xaiGrokBetaLlmDisabled,
    xaiGrok2LlmDisabled,
  ]);

  const handleChange = useCallback(
    (newLlm: SupportedLlm | null) => {
      setLlm(newLlm);
      handleLlmSelect(newLlm);
    },
    [handleLlmSelect]
  );

  return (
    <RadioList<SupportedLlm | null>
      hasEnterpriseFeatures={hasAIModelSelectionFeature}
      listItemClass="hover:cursor-pointer"
      nonEnterpriseOnlyListItemClass={nonEnterpriseOnlyListItemClass}
      onChange={handleChange}
      options={finalLlms}
      selected={llm}
      setPaywallModalOpen={setPaywallModalOpen}
    />
  );
};
