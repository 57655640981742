import React from 'react';
import { InviteMemberItem } from './InviteMemberItem';
import { InviteMember, UpdateMemberRoleFn } from './types';

interface InviteMembersListProps {
  title: string;
  members: InviteMember[];
  onRemove: (email: string) => void;
  updateMemberRole: UpdateMemberRoleFn;
}

export const InviteMembersList: React.FC<InviteMembersListProps> = ({
  title,
  members,
  onRemove,
  updateMemberRole,
}) => {
  if (members.length === 0) {
    return null;
  }

  return (
    <>
      <h3 className="font-semibold mt-0 mb-4">{title}</h3>
      {members.map(({ name, email, icon, admin }) => (
        <InviteMemberItem
          admin={admin}
          email={email}
          icon={icon}
          key={email}
          name={name}
          onRemove={onRemove}
          updateMemberRole={updateMemberRole}
        />
      ))}
    </>
  );
};
