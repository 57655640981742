import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Bot } from '../../models/Bots';
import { SupportedLlm } from '../../models/User';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../controls/ui/UIToolTip/UIToolTip';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../shadcn/lib/components/popover';
import { llms, LLMSelectList } from './LLMSelectList';

interface LLMSelectPopoverBtnProps {
  bot?: Bot;
  disabled?: boolean;
  selectedLlm: SupportedLlm | null;
  setSelectedLlm: (newLlm: SupportedLlm | null) => void;
  setOpenPaywallModal(): void;
}

export const LLMSelectPopoverBtn: FC<LLMSelectPopoverBtnProps> = ({
  bot,
  selectedLlm,
  setSelectedLlm,
  setOpenPaywallModal,
  disabled,
}) => {
  const [openSelect, setOpenSelect] = useState(false);

  const llmIcon = useMemo(
    () => llms.find((llm) => llm.value === selectedLlm)?.icon,
    [selectedLlm]
  );

  const handleLlmSelect = useCallback(
    (newLlm: SupportedLlm | null) => {
      setSelectedLlm(newLlm);
      setOpenSelect(false);
    },
    [setSelectedLlm]
  );

  const handleOpenSelect = useCallback(() => {
    if (bot || disabled) {
      setOpenSelect(false);
      return;
    }

    setOpenSelect((prev) => !prev);
  }, [bot, setOpenSelect, disabled]);

  useEffect(() => {
    if (bot) {
      handleLlmSelect(bot.preferred_llm);
    }
  }, [bot, handleLlmSelect]);

  return (
    <Popover onOpenChange={handleOpenSelect} open={openSelect}>
      <PopoverTrigger asChild>
        <div className="w-fit">
          <UITooltip title="Select LLM">
            <div
              className={`flex items-center hover:bg-cloud-10 p-2 rounded-md cursor-pointer border-gray-30 border-solid border ${
                bot || disabled ? 'opacity-50' : ''
              }`}
            >
              {llmIcon}
              <UIIcon
                className="sm:inline hidden hover:pt-1"
                name="arrow-down"
              />
            </div>
          </UITooltip>
        </div>
      </PopoverTrigger>
      <PopoverContent className="z-[1000] bg-white p-0 mr-2 sm:mr-0 max-h-80 overflow-y-auto custom-scrollbar">
        <LLMSelectList
          defaultLlm={selectedLlm}
          handleLlmSelect={handleLlmSelect}
          nonEnterpriseOnlyListItemClass="hover:bg-cloud-10"
          setPaywallModalOpen={setOpenPaywallModal}
        />
      </PopoverContent>
    </Popover>
  );
};
