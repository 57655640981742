import { IconProps } from '../../controls/ui/UIIcon/UIIcon';

export function getIconPropsByFileName(fileName: string): IconProps {
  const extension = fileName.split('.').pop()?.toLowerCase() ?? '';

  switch (extension) {
    case 'doc':
    case 'docx':
    case 'rtf':
      return { name: 'word', type: 'files' };

    case 'xls':
    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
    case 'xltx':
    case 'xltm':
    case 'ods':
    case 'numbers':
    case 'tsv':
    case 'csv':
      return { name: 'spreadsheet', type: 'files' };

    case 'ppt':
    case 'pptx':
    case 'odp':
      return { name: 'presentation', type: 'files' };

    case 'pdf':
      return { name: 'adobe-pdf', type: 'files' };

    case 'txt':
      return { name: 'doc', type: 'ui' };

    default:
      return { name: 'file-other', type: 'ui' };
  }
}
