import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { PaywallModal } from '../../components/admin/EnterprisePaywall/EnterprisePaywall';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import { QAFeedbackModal } from '../../components/jit-qa/QAFeedbackModal';
import { QAMessageView } from '../../components/jit-qa/QAMessageView';
import { QAPageAnswerView } from '../../components/jit-qa/QAPageView';
import { QAStreamingMessage } from '../../components/jit-qa/QAStreamingMessage';
import { QAFileDragNDrop } from '../../components/jit-qa/files/QAFileDragNDrop';
import { QATextInputView } from '../../components/jit-qa/textInputBox/QATextInputView';
import { QAWorkflowTemplateModal } from '../../components/workflowModal/QAWorkflowTemplateModal';
import { trackEvent } from '../../extra/sharedMethods';
import { QAStages } from '../../models/QAmodels';
import { useQAController } from '../../scripts/QAController';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useBoolState } from '../../scripts/hooks';
import { useBots } from '../../scripts/hooks/bots';

export const SidePanelPage: FC = () => {
  const qaController = useQAController();
  const { bots } = useBots();

  const messages = qaController.useCurrentTopicMessages();
  const { isNewTopic } = qaController.getIsNewTopic();
  const { currentStage } = qaController.useProgressStage();

  const [openPaywallModal, setOpenPaywallModal, setNotOpenPaywallModal] =
    useBoolState(false);

  const conversationId = useMemo(() => {
    return messages[0]?.conversation_id;
  }, [messages]);

  const handleNewTopic = useCallback(() => {
    qaController.setCurrentTopicMessages([]);
  }, [qaController]);

  useEffect(() => {
    trackEvent(AnalyticsEvent.SidePanelOpened);
  }, []);

  return (
    <QAFileDragNDrop>
      <div className="flex h-screen">
        <div className="flex flex-1 flex-col h-full relative">
          {messages.length === 0 && (
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full flex flex-col justify-center items-center gap-4">
              <UIIcon name="dashworks" size={64} type="apps" />
              <div className="text-2xl font-semibold">
                How may I help you today?
              </div>
            </div>
          )}

          <div className="flex-grow overflow-auto scrollbar scrollbar-track-white scrollbar-thumb-gray-30 scrollbar-thin">
            <div className="max-w-3xl mx-auto mb-8 mt-2 w-[100vw]">
              <div>
                {messages.map((message) => (
                  <QAMessageView
                    bots={bots}
                    isViewOnly={false}
                    key={message.row_id}
                    qaMessage={message}
                    sharableConversation={messages}
                  />
                ))}
                <QAStreamingMessage bots={bots} topic_id={conversationId} />
              </div>
            </div>
          </div>

          <div className="sticky bottom-0 bg-white">
            <QATextInputView
              disableNewTopicButton={
                isNewTopic || currentStage !== QAStages.WAIT_USER_INPUT
              }
              disableNewTopicButtonReason={
                currentStage === QAStages.WAIT_USER_INPUT
                  ? 'Already on a new topic'
                  : 'Please wait for the current answer to finish generating'
              }
              hideRecommendedWorkflows
              newTopicCallback={handleNewTopic}
              setOpenPaywallModal={setOpenPaywallModal}
            />
          </div>
          <QAPageAnswerView />
          <QAWorkflowTemplateModal setOpenPaywallModal={setOpenPaywallModal} />
          <QAFeedbackModal sharableConversation={messages} />
        </div>

        {openPaywallModal && (
          <PaywallModal allowUpgrade closeModal={setNotOpenPaywallModal} />
        )}
      </div>
    </QAFileDragNDrop>
  );
};
