import React from 'react';
import { UserImage } from '../../controls/UserImage/UserImage';

interface MemberCardContentProps {
  email: string;
  name?: string;
  icon?: string;
}

export const MemberCardContent = ({
  name,
  email,
  icon,
}: MemberCardContentProps): JSX.Element => {
  return (
    <>
      <UserImage displayName={name ?? email} imageUrl={icon} size={30} />
      <div>
        <div className="text-base">{name ?? email}</div>
        <div className="text-sm text-cloud-30 italic">{name && email}</div>
      </div>
    </>
  );
};
