import React, { ReactNode, useState } from 'react';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../shadcn/lib/components/popover';

interface IProfileInfoHoverProps {
  icon: ReactNode;
  name: string;
  description?: string;
  customTriggerIcon?: ReactNode;
}

export const ProfileInfoHover: React.FC<IProfileInfoHoverProps> = ({
  icon,
  name,
  description,
  customTriggerIcon,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <Popover onOpenChange={setPopoverOpen} open={popoverOpen}>
      <PopoverTrigger
        asChild
        className={customTriggerIcon ? '' : 'hidden sm:block'}
      >
        <div
          className="flex h-fit"
          onMouseEnter={() => {
            setPopoverOpen(true);
          }}
          onMouseLeave={() => {
            setPopoverOpen(false);
          }}
        >
          {customTriggerIcon ?? <UIIcon name="info" />}
        </div>
      </PopoverTrigger>
      <PopoverContent
        className="flex flex-col w-80 z-[999999] p-0"
        side="right"
      >
        <div className="flex gap-2 px-5 py-2 items-center">
          {icon}
          <div className="font-medium">{name}</div>
        </div>
        {description && (
          <>
            <div className="border-b" />

            <div className="flex-col px-5 py-2">
              <div className="text-cloud-40">Description</div>
              <div className="break-all">{description}</div>
            </div>
          </>
        )}
      </PopoverContent>
    </Popover>
  );
};
