import './MembersPage.scss';
import React, { FC, useCallback } from 'react';
import { InviteMembersModal } from '../../../components/admin/InviteMembersModal/InviteMembersModal';
import { MembersTable } from '../../../components/admin/MembersTable/MembersTable';
import { MembersTableDialog } from '../../../components/admin/MembersTableDialog/MembersTableDialog';
import { UIButton } from '../../../components/controls/ui/UIButton/UIButton';
import { UIIcon } from '../../../components/controls/ui/UIIcon/UIIcon';
import {
  TabDefinition,
  UITabs,
} from '../../../components/controls/ui/UITabs/UITabs';
import { useAdminGuard, useBoolState, useFlag } from '../../../scripts/hooks';
import { MemberStatus } from '../../../scripts/models/org-member';
import { capitalize } from '../../../scripts/utils';

enum MemberPageTabType {
  ALL_USERS = 'ALL_USERS',
  SIGNED_UP = 'SIGNED_UP',
  INVITED = 'INVITED',
  BLOCKED = 'BLOCKED',
}

const MEMBER_TABS: TabDefinition[] = Object.keys(MemberPageTabType).map(
  (key: string) => ({
    label: capitalize(key.replace('_', ' ')),
    key,
  })
);

export const MembersPage: FC = () => {
  useAdminGuard();
  const [
    openInviteMembersDialog,
    setOpenInviteMembersDialog,
    setClosedInviteMembersDialog,
  ] = useBoolState(false);

  const simplerInviteExperience = useFlag('simplerInviteExperience');

  const getTabContent = useCallback((index: number) => {
    return (
      <MemberPageTab
        tabType={
          MemberPageTabType[MEMBER_TABS[index]!.key as MemberPageTabType]
        }
      />
    );
  }, []);

  return (
    <div className="mx-2 lg:mx-auto border-transparent my-24 max-w-5xl membersPage">
      <div className="flex gap-2 items-center">
        <UIIcon name="people" size={32} />
        <h2 className="text-2xl m-0 font-bold">Members</h2>
      </div>
      <div className="flex p-6 gap-4 rounded-lg bg-cloud-15 justify-between items-center mt-9 max-sm:p-4">
        <div>Manage your team and invite new members to try Dashworks.</div>

        <UIButton onClick={setOpenInviteMembersDialog}>Invite Members</UIButton>
      </div>

      <UITabs
        className="mt-10 memberTabs"
        getTabContent={getTabContent}
        initialSelectedTabIndex={1}
        tabs={MEMBER_TABS}
      />

      {simplerInviteExperience ? (
        <InviteMembersModal
          onClose={setClosedInviteMembersDialog}
          open={openInviteMembersDialog}
        />
      ) : (
        <MembersTableDialog
          onClose={setClosedInviteMembersDialog}
          open={openInviteMembersDialog}
        />
      )}
    </div>
  );
};

const MemberPageTab: FC<{ tabType: MemberPageTabType }> = ({ tabType }) => {
  let status: MemberStatus | undefined;
  switch (tabType) {
    case MemberPageTabType.ALL_USERS: {
      status = undefined;
      break;
    }

    case MemberPageTabType.SIGNED_UP: {
      status = MemberStatus.SIGNED_UP;
      break;
    }

    case MemberPageTabType.INVITED: {
      status = MemberStatus.INVITED;
      break;
    }

    case MemberPageTabType.BLOCKED: {
      status = MemberStatus.BLOCKED;
      break;
    }
  }

  return <MembersTable filterStatus={status} />;
};
