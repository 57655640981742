import React from 'react';
import { useHistory } from 'react-router-dom';
import { getAppDisplayName } from '../../apps/definition';
import { UserImage } from '../../components/controls/UserImage/UserImage';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../../components/controls/ui/UIToolTip/UIToolTip';
import { SidebarRoutes } from '../../containers/SidebarContainer';
import { JoinedMembers, JoinedOrgs, MarketingMaterialType } from './types';
import { APPS_FOR_MARKETING } from './utils';

interface BaseMarketingMaterialProps {
  type: MarketingMaterialType;
}

interface OrgMarketingMaterialProps extends BaseMarketingMaterialProps {
  type: MarketingMaterialType.ORGS;
  data: JoinedOrgs[];
}

interface MemberMarketingMaterialProps extends BaseMarketingMaterialProps {
  type: MarketingMaterialType.MEMBERS;
  data: JoinedMembers[];
}

type MarketingMaterialProps =
  | MemberMarketingMaterialProps
  | OrgMarketingMaterialProps;

export const MarketingMaterial = ({
  type,
  data,
}: MarketingMaterialProps): JSX.Element => {
  const history = useHistory();

  return (
    <div>
      <div className="sm:mx-16 border-b" />
      <div className="mt-7 mx-auto max-w-3xl px-2 sm:px:0">
        <div className="flex items-center justify-between rounded neumorphic-background border border-gray-20 border-solid p-5">
          <div>
            {type === MarketingMaterialType.ORGS
              ? 'Get instant answers to your work questions with Dashworks'
              : 'Try Dashworks and get answers to all your work questions'}
          </div>
          {type === MarketingMaterialType.ORGS ? (
            <UIButton
              onClick={() => {
                history.push('/signup');
              }}
            >
              Sign Up
            </UIButton>
          ) : (
            <UIButton
              onClick={() => {
                history.push(SidebarRoutes.JitQA);
              }}
            >
              Start a chat
            </UIButton>
          )}
        </div>
        <div className="flex sm:flex-row flex-col gap-8 justify-center mb-8 mt-6">
          <div>
            <div className="font-medium">
              {type === MarketingMaterialType.ORGS
                ? 'Powering AI answers for industry leaders, from hypergrowth startups to Fortune 500.'
                : 'Team members using Dashworks as their Knowledge Assistant.'}
            </div>
            <div className="flex sm:mt-6 mt-4">
              {type === MarketingMaterialType.ORGS
                ? data.map((org) => (
                    <div
                      className="inline-flex justify-center items-center w-14 h-14 gap-1 rounded-[28px] bg-white p-2 -mr-3"
                      key={org.logo}
                      style={{ border: '1px solid #E5E7EB' }}
                    >
                      <UITooltip title={org.name}>
                        <img
                          alt={org.name}
                          className="w-8 h-8"
                          src={org.logo}
                        />
                      </UITooltip>
                    </div>
                  ))
                : data.map((teamMate) => (
                    <div className="-mr-3" key={teamMate.email}>
                      <UITooltip title={teamMate.email}>
                        <div>
                          <UserImage
                            displayName={teamMate.name}
                            imageUrl={teamMate.icon}
                            size={48}
                          />
                        </div>
                      </UITooltip>
                    </div>
                  ))}
            </div>
          </div>
          <div className="border-r border-l-0 border-b-0 border-gray-20 border-solid" />

          <div>
            <div className="font-medium">The AI Answer Assistant for Work</div>
            <div className="sm:mt-6 mt-4 min-w-[240px]">
              {APPS_FOR_MARKETING.map((app) => (
                <UITooltip key={app} title={getAppDisplayName(app)}>
                  <div
                    className="inline-flex justify-center items-center w-14 h-14 gap-1 rounded-[28px] bg-white p-2 -mr-3"
                    style={{ border: '1px solid #E5E7EB' }}
                  >
                    <UIIcon name={app} size={30} type="apps" />
                  </div>
                </UITooltip>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
