import React, { FC } from 'react';
import { Bot } from '../../models/Bots';
import { QAStages } from '../../models/QAmodels';
import { useQAController } from '../../scripts/QAController';
import { QAMessageView } from './QAMessageView';

interface IQAStreamingMessage {
  topic_id?: string;
  bots: Bot[];
  showAllSources?: boolean;
}

export const QAStreamingMessage: FC<IQAStreamingMessage> = ({
  topic_id,
  bots,
  showAllSources = false,
}) => {
  const qaController = useQAController();
  const { currentStage } = qaController.useProgressStage();
  const streamingAnswerMessage = qaController.useStreamingAnswer();

  if (topic_id !== streamingAnswerMessage?.conversation_id) {
    return null;
  }

  return (
    <QAMessageView
      bots={bots}
      isAnswerStreaming={currentStage === QAStages.STREAMING_ANSWER}
      isLoading={currentStage !== QAStages.WAIT_USER_INPUT}
      isViewOnly={false}
      qaMessage={streamingAnswerMessage}
      showAllSources={showAllSources}
    />
  );
};
