import * as Sentry from '@sentry/browser';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import type { Subscription } from 'zen-observable-ts';
import { PageRouter } from './PageRouter';
import { Analytics } from './components/hidden/Analytics';
import { LoadingSplash } from './components/splash/LoadingSplash';
import {
  managedBotsConversationsSubscription,
  sharedConversationsSubscription,
  subscribeUserUpdate,
} from './graphql/subscriptions';
import {
  ManagedBotsConversationsSubscriptionResponse,
  processConversationResponse,
  SharedConversationsSubscriptionResponse,
} from './models/GQLResponse';
import { OrgByOrg, User } from './models/User';
import {
  addSharedConversations,
  setBotsAdminsByAdminId,
  setUser,
} from './redux/actions';
import { batch, useDispatch } from './redux/store';
import {
  registerServiceWorker,
  unregisterServiceWorker,
} from './register-service-worker';
import { getPylonEmailHash } from './scripts/apis/pylon';
import {
  getLoginTokenFromLocalStorage,
  refreshAllTokensOrLogout,
  useBackgroundSyncAuthDataFromLocalStorage,
  useSyncAuthDataFromLocalStorage,
} from './scripts/authentication';
import { EventSource, MessageAction } from './scripts/constants/message-action';
import { setupGraphQLClient } from './scripts/graphql';
import {
  useCustomDomainEmailParam,
  useFlag,
  useGlobalState,
  useUser,
} from './scripts/hooks';
import {
  ManualFetchDataType,
  useManualDataFetch,
} from './scripts/hooks/manual-trigger-hooks';
import { AwsTokens } from './scripts/models/aws-tokens';
import { CommonTokens } from './scripts/models/login-tokens';
import { toggleSidebar } from './scripts/sidebar';
import { getStorageItem, setStorageItem, StorageKey } from './scripts/storage';
import { logDebug, logError, logErrorWithData } from './scripts/utils';

const sendSettingsToExtension = (
  userId: string,
  overrideNewTabPageOrgPolicy?: boolean
) => {
  window.postMessage({
    action: MessageAction.SetTrackingSettings,
    data: {
      userId,
      apiOrigin: API_ORIGIN,
      overrideNewTabPageOrgPolicy,
    },
    source: EventSource.WEB_APP,
  });
};

// eslint-disable-next-line max-lines-per-function
export const Authenticator: FC = () => {
  const dispatch = useDispatch();
  const sub = useRef<Subscription | null>(null);
  const sharedConversationsSubscriptionRef = useRef<Subscription | null>(null);
  const managedBotsConversationsSubscriptionRef = useRef<Subscription | null>(
    null
  );

  useBackgroundSyncAuthDataFromLocalStorage();
  const subsCountRef = useRef(0);
  const orgByOrgIdRef = useRef<OrgByOrg | null>(null);

  const [customDomainInEmail, setCustomDomainEmail] =
    useCustomDomainEmailParam();

  const reduxPopulator = useSyncAuthDataFromLocalStorage();

  const manualTriggerAppRefresh = useManualDataFetch(
    dispatch,
    ManualFetchDataType.APPS
  );

  useEffect(() => {
    // clear transactional parameter on login
    if (customDomainInEmail) {
      setCustomDomainEmail('', 'replaceIn');
    }
  }, [customDomainInEmail, setCustomDomainEmail]);

  const setupGraphQLSubscription = useCallback(
    (loginTokens: CommonTokens, awsTokens: AwsTokens) => {
      // Unsubscribe any existing client
      sub.current?.unsubscribe();
      const client = setupGraphQLClient(loginTokens.id_token);
      sub.current = client
        .subscribe<{ result: User }>({
          query: subscribeUserUpdate,
          // eslint-disable-next-line @cspell/spellchecker
          variables: { userid: awsTokens.userId || awsTokens.cognitoId },
        })
        .subscribe({
          next(res) {
            if (!res.data?.result) {
              logDebug('no user returned from gql');
              return;
            }

            const user = res.data.result;
            setStorageItem(StorageKey.GraphQLData, user, true);

            subsCountRef.current += 1;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (user.orgByOrgId) {
              orgByOrgIdRef.current = user.orgByOrgId;
            } else {
              logErrorWithData(
                new Error("Subscription didn't return orgByOrgId"),
                {
                  count: subsCountRef.current,
                }
              );

              if (orgByOrgIdRef.current) {
                user.orgByOrgId = orgByOrgIdRef.current;
              }
            }

            if (user.orgByOrgId.chatRetentionDays === null) {
              // To show up in Fullstory for debugging
              FS.log('info', `New user set ${user.userId} ${user.email}`);
            } else {
              // disable fullstory for orgs with chat retention policy
              FS.shutdown();
            }

            batch(() => {
              dispatch(setUser(user));
              manualTriggerAppRefresh();
            });

            sendSettingsToExtension(
              user.userId,
              user.orgByOrgId.preferences.override_new_tab_page ?? false
            );
          },
          error(err) {
            logError(err);
          },
        });

      sharedConversationsSubscriptionRef.current?.unsubscribe();
      sharedConversationsSubscriptionRef.current = client
        .subscribe<{ result: SharedConversationsSubscriptionResponse }>({
          query: sharedConversationsSubscription,
          variables: { userid: awsTokens.userId || awsTokens.cognitoId },
          fetchPolicy: 'no-cache',
        })
        .subscribe({
          next(res) {
            if (!res.data?.result) {
              logDebug(
                'no data returned from gql for shared conversations subscription'
              );

              return;
            }

            const conversationsResponse =
              res.data.result.conversationMemberships.nodes;

            const topics = conversationsResponse
              .filter(
                (node) =>
                  !node.conversation.archived && node.conversation.summary
              )
              .map((node) => {
                return processConversationResponse(node.conversation);
              });

            dispatch(addSharedConversations(topics));
          },
          error(err) {
            logError(err);
          },
        });

      managedBotsConversationsSubscriptionRef.current?.unsubscribe();
      managedBotsConversationsSubscriptionRef.current = client
        .subscribe<{ result: ManagedBotsConversationsSubscriptionResponse }>({
          query: managedBotsConversationsSubscription,
          variables: { userid: awsTokens.userId || awsTokens.cognitoId },
          fetchPolicy: 'no-cache',
        })
        .subscribe({
          next(res) {
            if (!res.data?.result) {
              logDebug(
                'no data returned from gql for managed bots subscription'
              );

              return;
            }

            dispatch(
              setBotsAdminsByAdminId(res.data.result.botsAdminsByAdminId)
            );
          },
          error(err) {
            logError(err);
          },
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const populateReduxUserDataFromLocalStorage = useCallback(() => {
    reduxPopulator();
    // Set user object from localStorage if exists
    const userData = getStorageItem(
      StorageKey.GraphQLData,
      true,
      true
    ) as User | null;

    if (!userData) {
      return;
    }

    batch(() => {
      dispatch(setUser(userData));
      manualTriggerAppRefresh();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reduxPopulator]);

  const refreshTokens = useCallback(async () => {
    const [newLoginTokens, awsTokens] = await refreshAllTokensOrLogout();
    // Allow Sentry to identify user
    Sentry.configureScope((scope) => {
      scope.setUser({ email: newLoginTokens.username });
    });

    // We will set up a new GraphQL subscription on new token
    setupGraphQLSubscription(newLoginTokens, awsTokens);
  }, [setupGraphQLSubscription]);

  // Initialization
  useEffect(() => {
    // When auth tokens are first set, we refresh
    refreshTokens().catch(logError);

    populateReduxUserDataFromLocalStorage();

    const onWindowFocus = () => {
      // Refresh tokens to make sure we don't incur extra latency
      const tokens = getLoginTokenFromLocalStorage();
      if (!tokens) {
        location.reload();
        return;
      }

      // Refresh login ID token if it is going to expire soon

      if (tokens.expires_at * 1000 < Date.now() + 20 * 1000 * 60) {
        refreshTokens().catch(logError);
      }
    };

    // When user focuses on window, we try to refresh ID token
    window.addEventListener('focus', onWindowFocus);
    return () => {
      window.removeEventListener('focus', onWindowFocus);

      sub.current?.unsubscribe();
    };
  }, [refreshTokens, populateReduxUserDataFromLocalStorage]);

  const user = useUser();
  const userName = useGlobalState((state) => state.meta.userDisplayName);

  const enableServiceWorker = useFlag('enableServiceWorker');
  const featureFlagsFullyLoaded = useGlobalState(
    (s) => s.featureFlagsFullyLoaded
  );

  // this useEffect toggles the sidebar
  useEffect(() => {
    let prevWidth = window.innerWidth;

    const handleResize = () => {
      const currentWidth = window.innerWidth;

      /*
       * On mobile devices, when we scroll the web page, the address bar in the mobile browser is hidden, causing a vertical screen resize.
       * In this case, we don't want to toggle off the sidebar.
       */
      const isHorizontalResize = currentWidth !== prevWidth;

      if (isHorizontalResize && window.innerWidth < 1175) {
        toggleSidebar(false);
      }

      prevWidth = currentWidth;
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    async function setupPylon() {
      // eslint-disable-next-line no-inline-comments, @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (user && !window.pylon) {
        const emailHash = await getPylonEmailHash();
        if (emailHash) {
          // eslint-disable-next-line no-inline-comments, @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          window.pylon = {
            chat_settings: {
              // pylon id
              app_id: '3c7d10c8-b70a-463c-b0d2-ff4d5178630f',
              email: user.email,
              name: userName,
              email_hash: emailHash,
            },
          };
        }
      }
    }

    setupPylon();
  }, [user, userName]);

  useEffect(() => {
    if (!featureFlagsFullyLoaded) {
      return;
    }

    if (enableServiceWorker) {
      registerServiceWorker();
    } else {
      unregisterServiceWorker();
    }
  }, [enableServiceWorker, featureFlagsFullyLoaded]);

  if (window.name === 'google' || window.name === 'sso') {
    return null;
  }

  return user ? (
    <>
      <PageRouter />
      <Analytics />
    </>
  ) : (
    <LoadingSplash />
  );
};
