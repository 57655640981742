import React, { FC, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useToaster } from '../../../scripts/hooks';
import { logError, uniqBy } from '../../../scripts/utils';
import { Modal } from '../../controls/ui/Modal/Modal';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { InviteMembersList } from './InviteMembersList';
import { MembersSelect } from './MembersSelect';
import { EmailInviteType, InviteMember, UpdateMemberRoleFn } from './types';
import { sendEmailInvite } from './utils';

interface InviteMembersModalProps {
  open: boolean;
  onClose(): void;
}

export const InviteMembersModal: FC<InviteMembersModalProps> = ({
  open,
  onClose,
}) => {
  const [selectedMembers, setSelectedMembers] = useState<InviteMember[]>([]);
  const [inviting, setInviting] = useState(false);
  const toaster = useToaster();

  const [membersToSendNewInvite, membersToResendInvite] = useMemo(() => {
    return [
      selectedMembers.filter((member) => member.inviteType === 'new'),
      selectedMembers.filter((member) => member.inviteType === 'resend'),
    ];
  }, [selectedMembers]);

  const selectMember = useCallback((member: InviteMember) => {
    setSelectedMembers((prev) => uniqBy([...prev, member], 'email'));
  }, []);

  const removeMember = useCallback((email: string) => {
    setSelectedMembers((prev) =>
      prev.filter((member) => member.email !== email)
    );
  }, []);

  const updateMemberRole: UpdateMemberRoleFn = useCallback(
    (email: string, admin: boolean) => {
      setSelectedMembers((prev) => {
        return prev.map((member) => {
          if (member.email === email) {
            return { ...member, admin };
          }

          return member;
        });
      });
    },
    []
  );

  const sendInvites = useCallback(() => {
    setInviting(true);
    const newInvitePromise = sendEmailInvite(EmailInviteType.NEW, {
      emails: membersToSendNewInvite.map((member) => ({
        email: member.email,
        make_admin: member.admin,
      })),
    });

    const resendInvitePromise = sendEmailInvite(EmailInviteType.RESEND, {
      emails: membersToResendInvite.map((member) => ({
        email: member.email,
        make_admin: member.admin,
      })),
    });

    const handleInviteResults = (
      newInviteResult: PromiseSettledResult<void>,
      resendInviteResult: PromiseSettledResult<void>
    ) => {
      if (
        newInviteResult.status === 'rejected' &&
        resendInviteResult.status === 'rejected'
      ) {
        toaster.failure('Failed to invite members');
        return;
      }

      if (
        newInviteResult.status === 'fulfilled' &&
        resendInviteResult.status === 'fulfilled'
      ) {
        toaster.success('Invited members successfully');
        setSelectedMembers([]);
        onClose();
        return;
      }

      if (membersToSendNewInvite.length > 0) {
        if (newInviteResult.status === 'fulfilled') {
          toaster.success('Invited members successfully');
        } else {
          toaster.failure('Failed to send invites');
          setSelectedMembers(membersToSendNewInvite);
        }
      }

      if (membersToResendInvite.length > 0) {
        if (resendInviteResult.status === 'fulfilled') {
          toaster.success('Resent invites successfully');
        } else {
          toaster.failure('Failed to resend invites');
          setSelectedMembers(membersToResendInvite);
        }
      }
    };

    Promise.allSettled([newInvitePromise, resendInvitePromise])
      .then((results) => {
        const [newInviteResult, resendInviteResult] = results;
        handleInviteResults(newInviteResult, resendInviteResult);
      })
      .finally(() => {
        setInviting(false);
      });
  }, [membersToSendNewInvite, membersToResendInvite, toaster, onClose]);

  const copyInviteLink = useCallback(() => {
    const linkToShare = `${window.location.origin}/signup`;
    navigator.clipboard
      .writeText(linkToShare)
      .then(() => {
        toaster.success('Copied invite link to clipboard');
      })
      .catch(logError);
  }, [toaster]);

  if (!open) {
    return null;
  }

  return createPortal(
    <Modal modalClasses="!w-[520px]" onClose={onClose}>
      <h2 className="text-xl font-bold m-0 mb-4">Invite members</h2>
      <MembersSelect
        onMemberSelect={selectMember}
        selectedMembers={selectedMembers}
      />
      <div className="overflow-y-auto max-h-[200px] mt-4 px-1">
        <InviteMembersList
          members={membersToSendNewInvite}
          onRemove={removeMember}
          title="Invite"
          updateMemberRole={updateMemberRole}
        />
        <InviteMembersList
          members={membersToResendInvite}
          onRemove={removeMember}
          title="Resend invite"
          updateMemberRole={updateMemberRole}
        />
      </div>
      <footer className="flex justify-between mt-2">
        <UIButton onClick={copyInviteLink} type="secondary">
          <UIIcon className="mr-2" name="link" />
          Copy invite link
        </UIButton>
        <UIButton
          disabled={selectedMembers.length === 0}
          onClick={sendInvites}
          processing={inviting}
        >
          Invite
        </UIButton>
      </footer>
    </Modal>,
    document.body
  );
};
