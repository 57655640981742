import React, { useCallback, useRef, useState } from 'react';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import { withInstallModal } from '../../hoc/withInstallModal';

export const AppInstallModal = withInstallModal(
  ({ connectInProgress, handleAPIInstall }) => {
    const textboxRef = useRef<HTMLInputElement>(null);

    const [url, setUrl] = useState('');
    const [apiError, setApiError] = useState('');

    const handleClickDone = useCallback(async () => {
      return handleAPIInstall({ url }).catch(() => {
        setApiError('Please enter a valid URL.');
        textboxRef.current?.focus();
      });
    }, [handleAPIInstall, url]);

    const connectDisabled = url.trim().length === 0;

    return (
      <div className="flex flex-col gap-4">
        <UITextbox
          className="stepFieldInput"
          defaultWhiteInputField
          error={apiError}
          onChange={setUrl}
          placeholder="Website URL"
          ref={textboxRef}
          size="large"
          value={url}
        />
        <div className="flex gap-2">
          <div>
            <UIIcon name="info" />
          </div>
          Dashworks can search public websites that are searchable by web search
          engines like Google and Bing. Dashworks will exclusively search within
          that specific directory and its subdirectories.
        </div>
        {connectInProgress(handleClickDone, connectDisabled)}
      </div>
    );
  }
);
