import React from 'react';
import { FilesDragNDrop } from '../../files/FilesDragNDrop';
import { acceptedMimeTypes } from '../../files/utils';
import { useQAFiles } from './QAFilesContext';

interface QAFileDragNDDropProps {
  children: React.ReactNode;
}

export const QAFileDragNDrop = ({
  children,
}: QAFileDragNDDropProps): JSX.Element => {
  const { uploadFiles, uploadStatus } = useQAFiles();

  return (
    <FilesDragNDrop
      dropFilePlaceholder="Drop your files to ask questions about it"
      maxFiles={50}
      supportedFileTypes={acceptedMimeTypes}
      uploadFiles={uploadFiles}
      uploadStatus={uploadStatus}
    >
      {children}
    </FilesDragNDrop>
  );
};
