import React, { useState } from 'react';
import { useToaster } from '../../scripts/hooks';
import { UploadStatus } from '../../scripts/hooks/files';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';

interface FileDragNDDropProps {
  children: React.ReactNode;
  uploadFiles: (files: File[]) => void;
  supportedFileTypes: Set<string>;
  uploadStatus: UploadStatus;
  maxFiles?: number;
  dropFilePlaceholder?: string;
}

const preventDefault = (e: React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

export const FilesDragNDrop = ({
  children,
  supportedFileTypes,
  maxFiles,
  uploadStatus,
  uploadFiles,
  dropFilePlaceholder = 'Drop your files here',
}: FileDragNDDropProps): JSX.Element => {
  const [isOver, setIsOver] = useState(false);
  const toaster = useToaster();

  const onFilesDrop = (files: FileList) => {
    const supportedFiles: File[] = [];
    const notSupportedFiles: File[] = [];

    if (uploadStatus === UploadStatus.TRANSIT) {
      toaster.failure('Please wait for the current file(s) to upload');
      return;
    }

    if (maxFiles && files.length > maxFiles) {
      toaster.failure(
        `You can only upload ${maxFiles} file${
          maxFiles > 1 ? 's' : ''
        } at a time`
      );

      return;
    }

    for (const file of files) {
      if (supportedFileTypes.has(file.type)) {
        supportedFiles.push(file);
      } else {
        notSupportedFiles.push(file);
      }
    }

    if (supportedFiles[0]) {
      uploadFiles(supportedFiles);
    }

    if (notSupportedFiles.length) {
      const notSupportedFileTypes = Array.from(
        new Set(notSupportedFiles.map((file) => file.name.split('.').pop()))
      );

      toaster.failure(
        `${notSupportedFileTypes.join(', ')} file type${
          notSupportedFileTypes.length === 1 ? ' is ' : 's are'
        } not supported`
      );
    }
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    preventDefault(e);
    onFilesDrop(e.dataTransfer.files);
    setIsOver(false);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    preventDefault(e);
    setIsOver(true);
  };

  const onOuterDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    preventDefault(e);
    setIsOver((prev) => prev || false);
  };

  const onInnerDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    preventDefault(e);
    setIsOver(false);
  };

  return (
    <div onDragLeave={onOuterDragLeave} onDragOver={onDragOver}>
      {isOver && (
        <div
          className="absolute top-0 left-0 bottom-0 right-0 text-white bg-gray-60 opacity-90 z-[150] flex flex-col justify-center items-center gap-2"
          onDragLeave={onInnerDragLeave}
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          <UIIcon
            className="text-white w-20 h-20 mb-3"
            name="files"
            size={32}
            type="ui"
          />
          <div className="font-medium text-2xl text-center px-4">
            {dropFilePlaceholder}
          </div>

          <UIIcon
            className="text-white mb-3 absolute top-5 right-5 cursor-pointer"
            name="cross"
            onClick={() => {
              setIsOver(false);
            }}
            size={32}
            type="ui"
          />
        </div>
      )}

      {children}
    </div>
  );
};
