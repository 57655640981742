import React from 'react';
import { acceptedMimeTypes } from '../../files/utils';

interface Props {
  onFilesAttached: (files: File[]) => void;
  children: React.ReactNode;
  disabled?: boolean;
}

export const AttachFile = ({
  onFilesAttached,
  children,
  disabled = false,
}: Props): JSX.Element => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div className="relative" onClick={() => fileInputRef.current?.click()}>
      {children}
      <input
        accept={Array.from(acceptedMimeTypes).join(',')}
        className="absolute opacity-0 cursor-pointer top-0 left-0 right-0 bottom-0 z-[-1]"
        disabled={disabled}
        multiple
        onChange={(e) => {
          const { files } = e.target;

          if (files?.length) {
            onFilesAttached(Array.from(files));
          }
        }}
        ref={fileInputRef}
        type="file"
      />
    </div>
  );
};
