import React from 'react';
import { OrgMember } from '../../../scripts/models/org-member';
import { MemberCardContent } from './MemberCardContent';

interface MembersSelectListProps {
  members: OrgMember[];
  onMemberSelect: (member: OrgMember) => void;
}

export const MembersSelectList = ({
  members,
  onMemberSelect,
}: MembersSelectListProps): JSX.Element => {
  return (
    <div>
      {members.map((member) => (
        <button
          className="flex items-center gap-2 p-2 cursor-pointer hover:bg-cloud-10 resetButton"
          key={member.email}
          onClick={() => {
            onMemberSelect(member);
          }}
          type="button"
        >
          <MemberCardContent
            email={member.email}
            icon={member.icon}
            name={member.name}
          />
        </button>
      ))}
    </div>
  );
};
