import { invokeAWSGatewayAPI } from '../../../scripts/apis/aws';
import { EmailInviteType, SendMailInviteArgs } from './types';

export const sendEmailInvite = async (
  emailInviteType: EmailInviteType,
  params: SendMailInviteArgs
): Promise<void> => {
  if (params.emails.length === 0) {
    return;
  }

  await invokeAWSGatewayAPI({
    path: `/members/action/${emailInviteType}`,
    method: 'POST',
    data: params,
  });
};

export const isValidEmail = (email: string): boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};
