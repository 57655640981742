import './OpenSidebar.scss';
import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import { useFlag, useSidebarOpen } from '../../../../scripts/hooks';
import { useContrast } from '../../../../scripts/hooks/colors';
import { useIsHomepage } from '../../../../scripts/hooks/location';
import { toggleSidebar } from '../../../../scripts/sidebar';
import { CompanyLogo } from '../../../controls/CompanyLogo/CompanyLogo';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import { NotificationsBadge } from '../../../notifications/NotificationBadge';
import { useNotifications } from '../../../notifications/NotificationsContext';

export const OpenSidebar: FC = () => {
  const sidebarOpen = useSidebarOpen();
  const isHomepage = useIsHomepage();
  const { metaData: notificationsMetaData } = useNotifications();
  const knockNotificationsEnabled = useFlag('knockNotificationsEnabled');

  const onOpenClick = useCallback(() => {
    toggleSidebar(true);
  }, []);

  const openSidebarClasses = classNames('openSidebar z-[130]', {
    hidden: sidebarOpen,
    contrast: useContrast() && isHomepage,
    'bg-white md:shadow': !isHomepage,
  });

  return (
    <div className={openSidebarClasses} onClick={onOpenClick}>
      <CompanyLogo />
      <div className="relative">
        <UIIcon name="menu" size={24} />
        {knockNotificationsEnabled && notificationsMetaData && (
          <NotificationsBadge
            className="absolute top-0 right-0 translate-x-1/2 translate-y-[-25%]"
            count={notificationsMetaData.unread_count}
          />
        )}
      </div>
    </div>
  );
};
