import { Box, Tab, Tabs } from '@mui/material';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import {
  Bot,
  RecommendedSlackbotChannel,
  SlackbotChannel,
} from '../../models/Bots';
import { AutoJoinChannelBody } from '../../scripts/hooks/recommendedSlackbotChannels';
import { UpdateSlackbotChannelParams } from '../../scripts/hooks/slackbotChannels';
import { BotsTab } from './tabs/BotsTab';
import { RecommendedSlackbotChannelsTab } from './tabs/RecommendedSlackbotChannelsTab';
import { SlackbotChannelsTab } from './tabs/SlackbotChannelsTab';

interface BotsTableProps {
  showRecommended: boolean;
  bots: Bot[];
  slackbotChannels: SlackbotChannel[];
  recommendedSlackbotChannels: RecommendedSlackbotChannel[];
  slackbotChannelsLoading: boolean;
  recommendedSlackbotChannelsLoading: boolean;
  setCurrentBot: (bot: Bot) => void;
  updateSlackbotChannel: (params: UpdateSlackbotChannelParams) => Promise<void>;
  getRecommendedSlackbotChannels: () => Promise<void>;
  autoJoinRecommendedSlackbotChannel: (
    body: AutoJoinChannelBody,
    autoJoinFailureToastMessage: ReactNode
  ) => Promise<void>;
}

enum TabsEnum {
  Bots = 0,
  SlackbotChannels = 1,
  RecommendedSlackbotChannels = 2,
}

export const BotsTable: FC<BotsTableProps> = ({
  showRecommended,
  bots,
  slackbotChannels,
  recommendedSlackbotChannels,
  slackbotChannelsLoading,
  recommendedSlackbotChannelsLoading,
  setCurrentBot,
  updateSlackbotChannel,
  getRecommendedSlackbotChannels,
  autoJoinRecommendedSlackbotChannel,
}: BotsTableProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = useCallback((_, newValue: number) => {
    setActiveTab(newValue);
  }, []);

  useEffect(() => {
    handleTabChange(null, activeTab);
  }, [activeTab, handleTabChange, bots]);

  return (
    <div>
      <Box
        className="mt-10 uiTabs MuiTabs-root Mui-selected"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs onChange={handleTabChange} value={activeTab} variant="scrollable">
          <Tab
            id={TabsEnum.Bots.toString()}
            label="Bots"
            sx={{
              textTransform: 'none',
            }}
          />

          <Tab
            id={TabsEnum.SlackbotChannels.toString()}
            label="Slack Channels"
            sx={{
              textTransform: 'none',
            }}
          />
          {showRecommended && (
            <Tab
              id={TabsEnum.RecommendedSlackbotChannels.toString()}
              label="Recommended Slack Channels"
              sx={{
                textTransform: 'none',
              }}
            />
          )}
        </Tabs>
      </Box>
      {activeTab === TabsEnum.Bots && (
        <BotsTab bots={bots} setCurrentBot={setCurrentBot} />
      )}

      {activeTab === TabsEnum.SlackbotChannels && (
        <SlackbotChannelsTab
          bots={bots}
          loading={slackbotChannelsLoading}
          slackbotChannels={slackbotChannels}
          updateSlackbotChannel={updateSlackbotChannel}
        />
      )}
      {activeTab === TabsEnum.RecommendedSlackbotChannels && (
        <RecommendedSlackbotChannelsTab
          autoJoinRecommendedSlackbotChannel={
            autoJoinRecommendedSlackbotChannel
          }
          getRecommendedSlackbotChannels={getRecommendedSlackbotChannels}
          loading={recommendedSlackbotChannelsLoading}
          recommendedSlackbotChannels={recommendedSlackbotChannels}
        />
      )}
    </div>
  );
};
