import React, { FC, useCallback } from 'react';
import { InstallChromeExtensionDialog } from '../../components/controls/InstallExtensionDialog/chrome/InstallExtensionDialog';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { withInstallModal } from '../../hoc/withInstallModal';
import { onSetMondayToken } from '../../scripts/apis/updateMondayCookie';
import { InstallDialogProps } from '../../scripts/app';
import {
  EventSource,
  MessageAction,
} from '../../scripts/constants/message-action';
import { useBoolState, useLowerCase } from '../../scripts/hooks';
import { useExtensionObserver } from '../../scripts/hooks/extension';
import { isChrome, logError } from '../../scripts/utils';
import { ConnectStep } from '../generic/Components';
import { SubdomainCaptureStep } from '../generic/SubdomainCaptureStep';

const handleInstallApp = () => {
  window.open(
    `https://auth.monday.com/oauth2/authorize?client_id=${MONDAY_CLIENT_ID}&response_type=install`,
    '_blank'
  );
};

const HybridInstallDialog = withInstallModal(
  ({ connectInProgress, handleOAuthInstall }) => {
    const [
      openInstallChromeExtensionDialog,
      setOpenInstallChromeExtensionDialog,
      setNotOpenInstallChromeExtensionDialog,
    ] = useBoolState(false);

    const [subdomain, setSubdomain] = useLowerCase('');
    const extensionInstalled = useExtensionObserver();
    const unsupportedBrowser = !isChrome;

    const handleClickDone = useCallback(async () => {
      try {
        await handleOAuthInstall({ subdomain });

        // Request the extension to refresh the integration cookie for Monday:
        window.addEventListener('message', onSetMondayToken);
        window.postMessage({
          action: MessageAction.RefreshMondayCookie,
          source: EventSource.WEB_APP,
          payload: {
            domain: `${subdomain}.monday.com`,
          },
        });
      } catch (error) {
        logError(error);
      }
    }, [handleOAuthInstall, subdomain]);

    const connectDisabled =
      subdomain.trim().length === 0 || !extensionInstalled || !isChrome;

    return (
      <>
        <ConnectStep step={1} title="Install Dashworks Extension">
          <p>
            Monday connection requires the Dashworks browser extension. Please
            contact support if you have questions.
          </p>
          {unsupportedBrowser ? (
            <p className="text-mahogany-20">
              Dashworks extension is only supported on Chrome. Please use Chrome
              to install the extension.
            </p>
          ) : (
            <UIButton
              disabled={extensionInstalled}
              onClick={setOpenInstallChromeExtensionDialog}
              size="large"
            >
              {extensionInstalled ? 'Extension installed' : 'Install'}
            </UIButton>
          )}
        </ConnectStep>

        <ConnectStep step={2} title="Install Dashworks App">
          <p>Allow Dashworks to access your Monday.com account.</p>
          <UIButton onClick={handleInstallApp} size="large">
            Install App
          </UIButton>
        </ConnectStep>

        <SubdomainCaptureStep
          additionalDescription={
            <strong>Please enter your Monday.com subdomain to continue.</strong>
          }
          hostname="monday.com"
          loginURL="https://monday.com/login"
          name="Monday"
          onChange={setSubdomain}
          step={3}
          subdomain={subdomain}
        />

        <InstallChromeExtensionDialog
          onClose={setNotOpenInstallChromeExtensionDialog}
          open={openInstallChromeExtensionDialog}
        />
        {connectInProgress(handleClickDone, connectDisabled)}
      </>
    );
  }
);

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  return (
    <HybridInstallDialog
      app={app}
      isOrg={isOrg}
      onClose={onClose}
      open={open}
    />
  );
};
