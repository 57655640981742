import { CreatorDetails } from '../../../models/Bots';
import { ConversationMember } from '../../../models/QAmodels';

export const convertConversationMemberToCreatorDetails = (
  member: ConversationMember
): CreatorDetails => {
  return {
    icon: member.icon,
    display_name: member.display_name,
    email: member.email,
  };
};
