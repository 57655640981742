import '../QAMessageListView';
import moment from 'moment';
import React, { FC } from 'react';

interface QATopicDividerProps {
  topicTitle?: string;
  topicTimestamp?: number;
}

export const QAMinimalTopicDivider: FC<QATopicDividerProps> = ({
  topicTitle,
  topicTimestamp,
}: QATopicDividerProps) => {
  return (
    <div className="relative bg-white pt-[26px] pb-6">
      <div className="border-[0.5px] border-solid border-cloud-15" />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex gap-1 px-4 bg-white whitespace-nowrap">
        <div>{topicTitle}</div>

        <div className="text-cloud-20 sm:block">|</div>
        <div className="font-light">
          {moment(topicTimestamp).format('MMM D, h:mm a')}
        </div>
      </div>
    </div>
  );
};
