import React, { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { QAMessageView } from '../../components/jit-qa/QAMessageView';
import { QAMinimalTopicDivider } from '../../components/jit-qa/topicDivider/QAMinimalTopicDivider';
import { LoadingSplash } from '../../components/splash/LoadingSplash';
import { SidebarRoutes } from '../../containers/SidebarContainer';
import { trackEvent } from '../../extra/sharedMethods';
import { QAMessage } from '../../models/QAmodels';
import { invokeFastApi } from '../../scripts/apis/fastapi';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { logDebug } from '../../scripts/utils';
import { MarketingMaterial } from './MarketingMaterial';
import { MarketingMaterialType } from './types';
import { ORGS_FOR_MARKETING } from './utils';

interface Author {
  displayName?: string;
  email: string;
  icon?: string;
}

export const MinimalTopicPage: FC = () => {
  const { id, msgId } = useParams<{ id: string; msgId?: string }>();

  const [loading, setLoading] = React.useState<boolean>(false);
  const history = useHistory();
  const [messages, setMessages] = React.useState<QAMessage[]>([]);

  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      try {
        const { messages: responseMessages } = await invokeFastApi<{
          author: Author;
          messages: QAMessage[];
          topic_files: string[];
        }>({
          path: `/topics/publicTopicById/${id}`,
          method: 'GET',
        });

        setMessages(responseMessages);
      } catch (error) {
        logDebug(error);
        history.push(SidebarRoutes.JitQA);
      }

      setLoading(false);
      trackEvent(AnalyticsEvent.ViewedPublicTopic, { topicId: id });
    };

    fetchMessages();
  }, [id, history]);

  const { topicTitle, topicTimestamp } = useMemo(() => {
    if (messages[0] && messages[0].conversation_id === id) {
      return {
        topicTitle: messages[0].topic_title,
        topicTimestamp: messages[0].conversation_timestamp,
      };
    }

    return {
      topicTitle: '',
      topicTimestamp: 0,
    };
  }, [messages, id]);

  if (loading) {
    return (
      <div className="flex h-screen">
        <LoadingSplash />
      </div>
    );
  }

  return (
    <div className="flex h-screen flex-col overflow-auto scrollbar-track-white scrollbar-thumb-gray-30 scrollbar-thin">
      <div className="flex flex-1 flex-col h-full">
        <div className="flex flex-col justify-between flex-grow">
          <div>
            <div className="sticky top-0 z-10 bg-white">
              <QAMinimalTopicDivider
                topicTimestamp={topicTimestamp}
                topicTitle={topicTitle}
              />
            </div>
            <div className="max-w-3xl mx-auto mb-8 mt-2 w-screen">
              <div>
                {messages.map((message) => (
                  <QAMessageView
                    bots={[]}
                    isFocused={message.message_id === msgId}
                    isViewOnly
                    key={message.row_id}
                    qaMessage={message}
                    sharableConversation={messages}
                    showAllSources={false}
                  />
                ))}
              </div>
            </div>
          </div>
          <MarketingMaterial
            data={ORGS_FOR_MARKETING}
            type={MarketingMaterialType.ORGS}
          />
        </div>
      </div>
    </div>
  );
};
