import { OrgMember } from '../../../scripts/models/org-member';

export interface InviteMember
  extends Pick<OrgMember, 'admin' | 'email' | 'icon' | 'name'> {
  inviteType: 'new' | 'resend';
}
export interface SendMailInviteArgs {
  emails: { email: string; make_admin?: boolean }[];
}

export enum EmailInviteType {
  NEW = 'send_invite',
  RESEND = 'resend_invite',
}

export type UpdateMemberRoleFn = (email: string, admin: boolean) => void;
