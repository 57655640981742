import { AppName } from '../../apps/definition';

export const ORGS_FOR_MARKETING = [
  {
    name: 'HeyGen',
    logo: 'https://cdn.filestackcontent.com/yHMvS5TcTmeNlPy4Xjul',
  },
  {
    name: 'Synthesia',
    logo: 'https://cdn.filestackcontent.com/0k3DGaHIQ9uUolnq1iPl',
  },
  {
    name: 'ButterflyMX',
    logo: 'https://cdn.filestackcontent.com/mvmLvb6PTjKoKDvtTccB',
  },
  {
    name: 'Swiggy',
    logo: 'https://cdn.filestackcontent.com/G96N0puTT4yKPQHcojIVhttps://cdn.filestackcontent.com/G96N0puTT4yKPQHcojIV',
  },
  {
    name: 'Contentstack',
    logo: 'https://cdn.filestackcontent.com/WLgC5pCzRHCfPb6Uyqpw',
  },
];

export const APPS_FOR_MARKETING: AppName[] = [
  'slack',
  'jira',
  'notion',
  'github',
  'gmail',
];
