import React, { ChangeEvent } from 'react';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { acceptedMimeTypes } from '../../components/files/utils';
import { UploadStatus } from '../../scripts/hooks/files';

interface FileUploadProps {
  uploadStatus: UploadStatus;
  uploadFiles: (
    file: File[],
    isZipFile: boolean,
    siteId: string,
    shouldRefetchFile?: boolean
  ) => Promise<void>;
  isZipFile?: boolean;
  siteId?: string;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  uploadStatus,
  uploadFiles,
  isZipFile = false,
  siteId = '',
}) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      uploadFiles(Array.from(files), isZipFile, siteId);
    }
  };

  return (
    <UIButton
      className="relative"
      disabled={uploadStatus === UploadStatus.DONE && isZipFile}
      processing={uploadStatus === UploadStatus.TRANSIT}
    >
      Upload {isZipFile ? 'Zip File' : 'File'}
      <input
        accept={
          isZipFile
            ? 'application/zip'
            : Array.from(acceptedMimeTypes).join(',')
        }
        className="absolute opacity-0 cursor-pointer"
        max={isZipFile ? 1 : 50}
        multiple={!isZipFile}
        onChange={handleFileChange}
        type="file"
      />
    </UIButton>
  );
};
