import './UISwitchRow.scss';
import React from 'react';
import { UISwitch } from '../UISwitch/UISwitch';

export const UISwitchRow: React.FC<{
  checked: boolean;
  onChange: (value: boolean) => void;
  label: string;
  description: string;
  disabled?: boolean;
}> = ({ onChange, checked, label, description, disabled }) => {
  return (
    <div className="uiSwitchRow">
      <div className="label">
        <div className="heading">{label}</div>
        <div className="caption">{description}</div>
      </div>
      <UISwitch checked={checked} disabled={disabled} onChange={onChange} />
    </div>
  );
};
