import './SidebarContainer.scss';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import {
  match,
  matchPath,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { MembersTableDialog } from '../components/admin/MembersTableDialog/MembersTableDialog';
import { Sidebar } from '../components/admin/Sidebar/Sidebar';
import { GatedRoute } from '../components/controls/GatedRoute/GatedRoute';
import { SupportChatBotBubble } from '../components/controls/SupportChatBotBubble/SupportChatBotBubble';
import { QAPageView } from '../components/jit-qa/QAPageView';
import { QAWrapper } from '../components/jit-qa/QAWrapper';
import { QAFileDragNDrop } from '../components/jit-qa/files/QAFileDragNDrop';
import { OpenSidebar } from '../components/pageSearch/top/OpenSidebar/OpenSidebar';
import { AppState } from '../constants';
import {
  AppcuesChecklistItemLabels,
  AppcuesEventNames,
  useAppcuesEvent,
} from '../hooks/appcuesHooks';
import { useHasValidSubscriptionPlan } from '../hooks/subscriptionHooks';
import { LazyNotificationsPreferencesPage } from '../pages/NotificationsPreferences';
import { LazyAiControlsPage } from '../pages/admin/AiControlsPage';
import { LazyAnalyticsPage } from '../pages/admin/AnalyticsPage';
import { LazyAnswersPage } from '../pages/admin/AnswersPage';
import { LazyApiKeysPage } from '../pages/admin/ApiKeysPage';
import { LazyAppearancePage } from '../pages/admin/AppearancePage';
import { AppsPage } from '../pages/admin/AppsPage/AppsPage';
import { BillingPage } from '../pages/admin/BillingPage/BillingPage';
import { LazyDirectorySyncPage } from '../pages/admin/DirectorySyncPage';
import { MembersPage } from '../pages/admin/MembersPage/MembersPage';
import { LazySecurityPage } from '../pages/admin/SecurityPage';
import { BotsPage } from '../pages/bots/BotsPage';
import { FilesPage } from '../pages/files/FilesPage';
import { LazyBrowserExtensionPage } from '../pages/home/BrowserExtensionPage';
import { LazyUserAiControlsPage } from '../pages/home/UserAiControlsPage';
import { LazyUserSettingsPage } from '../pages/home/UserSettingsPage';
import { MenuPage } from '../pages/search/MenuPage/MenuPage';
import { SidePanelPage } from '../pages/sidePanel/SidePanelPage';
import { TopicPage } from '../pages/topic/TopicPage';
import { UninstallExtensionPage } from '../pages/uninstall';
import { WorkflowsPage } from '../pages/workflows/WorkflowsPage';
import { setSidebarSettingsOpen } from '../redux/sidebar/actions';
import { useDispatch } from '../redux/store';
import { onSetFigmaToken } from '../scripts/apis/updateFigmaCookie';
import { onSetGongToken } from '../scripts/apis/updateGongCookie';
import { onSetMondayToken } from '../scripts/apis/updateMondayCookie';
import { onSetNotionToken } from '../scripts/apis/updateNotionCookie';
import {
  EventSource,
  MessageAction,
} from '../scripts/constants/message-action';
import {
  useBackground,
  useBoolState,
  useGlobalState,
  useHistoryPush,
  useOnlineStatus,
  useOrgPreference,
  usePrevious,
  useSidebarOpen,
  useTimeoutCreator,
  useToaster,
  useUserSafe,
} from '../scripts/hooks';
import { useExtensionObserver } from '../scripts/hooks/extension';
import { useIsHomepage } from '../scripts/hooks/location';
import { ToastType } from '../scripts/models/toast';
import { extensionEnabledBrowser, inSidePanel } from '../scripts/utils';

enum SettingsSidebarRoutes {
  AiControls = '/admin/ai-controls',
  Analytics = '/admin/analytics',
  Answers = '/answers/:id?',
  APIKeys = '/admin/api-keys',
  Appearance = '/admin/appearance',
  Apps = '/apps/:type/:app?',
  AppsV2 = '/integrations',
  Billing = '/admin/billing',
  Bots = '/bots',
  BrowserExtension = '/browser-extension',
  NotificationsPreferences = '/notification-preferences',
  DirectorySync = '/admin/directory-sync',
  Files = '/files',
  Members = '/admin/members/:path?',
  MyAiControls = '/my-ai-controls',
  MySettings = '/my-settings',
  SSO = '/admin/sso',
  Workflows = '/workflows',
}
enum PrimarySidebarRoutes {
  Root = '/',
  JitQA = '/chat',
  OldJitQA_V2 = '/dash-ai',
  OldJitQA_V1 = '/dashchat',
  Uninstall = '/uninstall',
  Topic = '/topic/:id/:msgId?',
  SidePanel = '/side-panel',
}

export const SidebarRoutes = {
  ...SettingsSidebarRoutes,
  ...PrimarySidebarRoutes,
};

const settingsPaths: string[] = Object.values(SettingsSidebarRoutes);
const routingPaths = Object.values(SidebarRoutes);
const nonTransitionParams = new Set(['app', 'path', 'id']);

let prevKey = 'init';
const newKey = () => {
  prevKey = Math.random().toString();
  return prevKey;
};

const useRouteChangeDetection = (): string => {
  const loc = useLocation();
  let newMatch: match | null = null;
  for (const path of routingPaths) {
    newMatch = matchPath(loc.pathname, {
      path,
      exact: true,
    });

    if (newMatch) {
      break;
    }
  }

  const oldMatch = usePrevious(newMatch);

  if (!oldMatch) {
    return newKey();
  }

  if (!newMatch) {
    return newKey();
  }

  if (newMatch.path !== oldMatch.path) {
    return newKey();
  }

  for (const [k, v] of Object.entries(newMatch.params)) {
    if (nonTransitionParams.has(k)) {
      continue;
    }

    if ((oldMatch.params as Record<string, string>)[k] !== v) {
      return newKey();
    }
  }

  return prevKey;
};

declare module 'react' {
  interface CSSProperties {
    '--background-image'?: string;
    '--background-fade-in-duration'?: string;
  }
}

const shouldUpdateCookie = (statusCode?: AppState) =>
  statusCode === AppState.Connecting ||
  statusCode === AppState.IndexingComplete ||
  statusCode === AppState.ErrorReconnect;

// eslint-disable-next-line max-lines-per-function
export const SidebarContainer: FC = () => {
  const user = useUserSafe();
  const dispatch = useDispatch();
  const sidebarOpen = useSidebarOpen();
  const location = useLocation();
  const isHomePage = useIsHomepage();
  const goToAdminBilling = useHistoryPush(SidebarRoutes.Billing);
  const onlineStatus = useOnlineStatus();
  const toaster = useToaster();
  const extensionInstalled = useExtensionObserver();
  const routeKey = useRouteChangeDetection();
  const [isImage, , backgroundImage] = useBackground();
  const [backgroundImageStyle, setBackgroundImageStyle] = useState('');
  const setTimeout = useTimeoutCreator();
  const [backgroundFadeInDuration, setBackgroundFadeInDuration] = useState(0.3);
  const [loadedNotionCookie, setLoadedNotionCookie] = useState(false);
  const [loadedFigmaCookie, setLoadedFigmaCookie] = useState(false);
  const [loadedGongCookie, setLoadedGongCookie] = useState(false);
  const [loadedMondayCookie, setLoadedMondayCookie] = useState(false);
  const answersEnabled = useOrgPreference('answers', true);

  const sidebarSettingsOpen = useGlobalState(
    (state) => state.sidebar.settingsOpen
  );

  const [openMembersDialog, setOpenMembersDialog, setClosedMembersDialog] =
    useBoolState(false);

  const hasValidSubscriptionPlan = useHasValidSubscriptionPlan();

  useEffect(() => {
    if (
      !hasValidSubscriptionPlan &&
      location.pathname !== SidebarRoutes.Billing
    ) {
      goToAdminBilling();
    }
  }, [hasValidSubscriptionPlan, location.pathname, goToAdminBilling]);

  useEffect(() => {
    if (sidebarSettingsOpen) return;

    let newMatch: match | null = null;
    for (const path of settingsPaths) {
      newMatch = matchPath(location.pathname, {
        path,
        exact: true,
      });

      if (newMatch && hasValidSubscriptionPlan) {
        dispatch(setSidebarSettingsOpen(true));
        break;
      }
    }
  }, [
    dispatch,
    location.pathname,
    hasValidSubscriptionPlan,
    sidebarSettingsOpen,
  ]);

  // Do not set image until image is fully loaded, then fade in
  useEffect(() => {
    setBackgroundImageStyle('');
    if (!isImage || !backgroundImage) {
      return;
    }

    setBackgroundFadeInDuration(0.3);

    const img = new Image();
    img.src = backgroundImage;

    const onImageLoaded = () => {
      setBackgroundImageStyle(`url("${backgroundImage}")`);
    };

    if (img.complete) {
      onImageLoaded();
      setBackgroundFadeInDuration(0);
      return;
    }

    img.addEventListener('load', onImageLoaded);
    return () => {
      img.removeEventListener('load', onImageLoaded);
    };
  }, [backgroundImage, isImage, setTimeout]);

  useEffect(() => {
    if (onlineStatus) {
      toaster.delete('onlineStatus');
    } else {
      toaster.sticky({
        uid: 'onlineStatus',
        message:
          'Network is offline, Dashworks cannot perform any searches at the moment.',
        type: ToastType.FAILURE,
      });
    }
  }, [dispatch, onlineStatus, toaster]);

  useAppcuesEvent(
    AppcuesEventNames.ChecklistItemStarted,
    AppcuesChecklistItemLabels.InviteYourTeam,
    setOpenMembersDialog
  );

  useEffect(() => {
    if (loadedNotionCookie) return;

    const updateNotionCookie = async (
      event: MessageEvent<{
        action: string;
        payload: { token: string };
      }>
    ) => {
      if (event.data.action !== 'dash-set-notion-token') {
        return;
      }

      await onSetNotionToken(event);
      setLoadedNotionCookie(true);
      window.removeEventListener('message', updateNotionCookie);
    };

    const {
      usersApps: { nodes },
    } = user;

    if (nodes.length > 0) {
      const notionConnection = nodes.find((app) => app.appName === 'notion');
      if (shouldUpdateCookie(notionConnection?.statusCode)) {
        window.removeEventListener('message', updateNotionCookie);

        if (extensionEnabledBrowser && extensionInstalled) {
          window.addEventListener('message', updateNotionCookie);
          window.postMessage({
            action: MessageAction.RefreshNotionCookie,
            source: EventSource.WEB_APP,
          });
        }
      }
    }
  }, [extensionInstalled, loadedNotionCookie, user]);

  useEffect(() => {
    if (loadedFigmaCookie) return;

    const updateFigmaCookie = async (
      event: MessageEvent<{
        action: string;
        payload: { token: string };
      }>
    ) => {
      if (event.data.action !== 'dash-set-figma-token') {
        return;
      }

      await onSetFigmaToken(event);
      setLoadedFigmaCookie(true);
      window.removeEventListener('message', updateFigmaCookie);
    };

    const {
      usersApps: { nodes },
    } = user;

    if (nodes.length > 0) {
      const figmaConnection = nodes.find((app) => app.appName === 'figma');
      if (shouldUpdateCookie(figmaConnection?.statusCode)) {
        window.removeEventListener('message', updateFigmaCookie);

        if (extensionEnabledBrowser && extensionInstalled) {
          window.addEventListener('message', updateFigmaCookie);
          window.postMessage({
            action: MessageAction.RefreshFigmaCookie,
            source: EventSource.WEB_APP,
          });
        }
      }
    }
  }, [extensionInstalled, loadedFigmaCookie, user]);

  useEffect(() => {
    if (loadedGongCookie) return;

    const updateGongCookie = async (
      event: MessageEvent<{
        action: string;
        payload: { token: string };
      }>
    ) => {
      if (event.data.action !== 'dash-set-gong-token') {
        return;
      }

      await onSetGongToken(event);
      setLoadedGongCookie(true);
      window.removeEventListener('message', updateGongCookie);
    };

    const {
      usersApps: { nodes },
    } = user;

    if (nodes.length > 0) {
      const gongConnection = nodes.find((app) => app.appName === 'gong');
      if (shouldUpdateCookie(gongConnection?.statusCode)) {
        window.removeEventListener('message', updateGongCookie);

        if (extensionEnabledBrowser && extensionInstalled) {
          window.addEventListener('message', updateGongCookie);
          window.postMessage({
            action: MessageAction.RefreshGongCookie,
            source: EventSource.WEB_APP,
          });
        }
      }
    }
  }, [extensionInstalled, loadedGongCookie, user]);

  useEffect(() => {
    if (loadedMondayCookie) return;

    const updateMondayCookie = async (
      event: MessageEvent<{
        action: string;
        payload: { token: string };
      }>
    ) => {
      if (event.data.action !== 'dash-set-monday-token') {
        return;
      }

      await onSetMondayToken(event);
      setLoadedMondayCookie(true);
      window.removeEventListener('message', updateMondayCookie);
    };

    const {
      usersApps: { nodes },
    } = user;

    if (nodes.length > 0) {
      const mondayConnection = nodes.find((app) => app.appName === 'monday');

      if (shouldUpdateCookie(mondayConnection?.statusCode)) {
        window.removeEventListener('message', updateMondayCookie);

        if (extensionEnabledBrowser && extensionInstalled) {
          window.addEventListener('message', updateMondayCookie);
          window.postMessage({
            action: MessageAction.RefreshMondayCookie,
            source: EventSource.WEB_APP,
            payload: {
              domain: mondayConnection?.workspaceId,
            },
          });
        }
      }
    }
  }, [extensionInstalled, loadedMondayCookie, user]);

  return (
    <div
      className={classNames('sidebarContainer', {
        withBackground: isHomePage && backgroundImageStyle,
      })}
      style={{
        '--background-image': backgroundImageStyle,
        '--background-fade-in-duration': `${backgroundFadeInDuration}s`,
      }}
    >
      <QAWrapper>
        {!inSidePanel() && (
          <>
            <Sidebar />
            <OpenSidebar />
          </>
        )}

        <div className={classNames('sidebarContentContainer', { sidebarOpen })}>
          <div className="sidebarContent" key={routeKey}>
            <Switch location={location}>
              <Redirect
                exact
                from={SidebarRoutes.OldJitQA_V1}
                to={SidebarRoutes.JitQA}
              />
              <Redirect
                exact
                from={SidebarRoutes.OldJitQA_V2}
                to={SidebarRoutes.JitQA}
              />
              <Route component={MenuPage} exact path={SidebarRoutes.Root} />
              {answersEnabled && (
                <Route
                  component={LazyAnswersPage}
                  path={SidebarRoutes.Answers}
                />
              )}
              <Route component={WorkflowsPage} path={SidebarRoutes.Workflows} />
              <Route component={BotsPage} path={SidebarRoutes.Bots} />
              <Route component={FilesPage} path={SidebarRoutes.Files} />
              {/* sidePanel route should only work inside side panel */}
              {window.self === window.top ? (
                <Redirect
                  exact
                  from={SidebarRoutes.SidePanel}
                  to={SidebarRoutes.Root}
                />
              ) : (
                <Route
                  component={SidePanelPage}
                  path={SidebarRoutes.SidePanel}
                />
              )}
              <Route
                component={UninstallExtensionPage}
                path={SidebarRoutes.Uninstall}
              />
              <Redirect from={'/apps/*'} to={SidebarRoutes.AppsV2} />

              <Route component={AppsPage} path={SidebarRoutes.AppsV2} />

              <Route
                component={LazyAppearancePage}
                exact
                path={SidebarRoutes.Appearance}
              />
              <Route
                component={MembersPage}
                exact
                path={SidebarRoutes.Members}
              />
              <Route
                component={LazyDirectorySyncPage}
                exact
                path={SidebarRoutes.DirectorySync}
              />
              <Route
                component={LazyBrowserExtensionPage}
                exact
                path={SidebarRoutes.BrowserExtension}
              />
              <Route
                component={BillingPage}
                exact
                path={SidebarRoutes.Billing}
              />
              <Route
                component={LazyAiControlsPage}
                exact
                path={SidebarRoutes.AiControls}
              />
              <Route
                component={LazySecurityPage}
                exact
                path={SidebarRoutes.SSO}
              />
              <GatedRoute
                component={LazyAnalyticsPage}
                exact
                flag="adminAnalytics"
                path={SidebarRoutes.Analytics}
              />
              <Route
                component={LazyApiKeysPage}
                exact
                path={SidebarRoutes.APIKeys}
              />
              <Route
                component={LazyUserSettingsPage}
                exact
                path={SidebarRoutes.MySettings}
              />
              <Route
                component={LazyUserAiControlsPage}
                exact
                path={SidebarRoutes.MyAiControls}
              />
              <Route
                component={LazyNotificationsPreferencesPage}
                exact
                path={SidebarRoutes.NotificationsPreferences}
              />
              <QAFileDragNDrop>
                <Route component={TopicPage} path={SidebarRoutes.Topic} />
                <Route
                  component={QAPageView}
                  exact
                  path={SidebarRoutes.JitQA}
                />
              </QAFileDragNDrop>
            </Switch>
            <SupportChatBotBubble />
          </div>
        </div>
      </QAWrapper>

      <MembersTableDialog
        onClose={setClosedMembersDialog}
        open={openMembersDialog}
      />
    </div>
  );
};
