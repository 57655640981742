export enum MessageAction {
  OpenExtensionNewTab = 'dash-open-extension-new-tab',
  RefreshNotionCookie = 'dash-refresh-notion-cookie',
  RefreshFigmaCookie = 'dash-refresh-figma-cookie',
  RefreshGongCookie = 'dash-refresh-gong-cookie',
  RefreshMondayCookie = 'dash-refresh-monday-cookie',
  SetTrackingSettings = 'dash-set-tracking-settings',
  SetUseNewTab = 'dash-set-use-new-tab',
  SetWidgetHiddenSites = 'dash-set-widget-hidden-sites',
  SetWidgetHiddenEverywhere = 'dash-set-widget-hidden-everywhere',
  SearchHistoryRequest = 'dash-search-history-request',
  SearchHistoryResponse = 'dash-search-history-response',
  SetCredentials = 'dash-set-credentials',
  UserLogout = 'dash-user-logout',
  ExtensionSettingsPageNavigate = 'dash-extension-settings-page-navigate',
  UpdateNotificationCount = 'dash-update-notification-count',
}

export enum EventSource {
  WEB_APP = 'web_app',
}

export interface MessageRequest {
  action: MessageAction;
  data?: unknown;
  source: EventSource;
}
