import './QAPageView.scss';
import React, { FC, useEffect, useMemo } from 'react';
import { useIsWorkspaceSetupNeeded } from '../../hooks/subscriptionHooks';
import { processConversationResponse } from '../../models/GQLResponse';
import { TopicType } from '../../models/QAmodels';
import { useAppConnectionSummary } from '../../pages/onboarding/WorkspaceSetupPage/hooks/app-connection-summary';
import { setUserPhotoUrl } from '../../redux/meta/actions';
import { useDispatch } from '../../redux/store';
import { QAController, QAControllerProvider } from '../../scripts/QAController';
import { getUserPhoto } from '../../scripts/apis';
import {
  useFlag,
  useGlobalState,
  useToaster,
  useUserSafe,
} from '../../scripts/hooks';
import { logError } from '../../scripts/utils';
import { NotificationsProvider } from '../notifications/NotificationsContext';
import { RealtimeChatProvider } from '../realtimeChat/RealtimeChatContext';
import { QAFilesProvider } from './files/QAFilesContext';
import { QATextInputBoxStatesProvider } from './textInputBox/QATextInputBoxStates';

export const QAWrapper: FC = ({ children }) => {
  const qaController = useMemo(() => new QAController(), []);
  const toaster = useToaster();
  const { instantAppConnectedCount } = useAppConnectionSummary();
  const dispatch = useDispatch();
  const isWorkspaceSetupNeeded = useIsWorkspaceSetupNeeded();
  const sharedConversations = useGlobalState(
    (state) => state.sharedConversations
  );

  const publicTopicsFeatureEnabled = useFlag('publicTopics');

  const {
    orgByOrgId: { publicTopicsEnabled: publicTopicsEnabledInOrg },
  } = useUserSafe();

  const botsAdminsByAdminId = useGlobalState(
    (state) => state.botsAdminsByAdminId
  );

  const hasConnectedAnyInstantApp = useMemo(
    () => instantAppConnectedCount > 0,
    [instantAppConnectedCount]
  );

  useEffect(() => {
    getUserPhoto()
      .then((response) => {
        dispatch(setUserPhotoUrl(response.photoUrl));
      })
      .catch(logError);
  }, [dispatch]);

  useEffect(() => {
    if (isWorkspaceSetupNeeded) return;

    qaController.fetchHistoryMessages(true).catch((error) => {
      toaster.failure(
        'Failed to fetch Dashworks messages, please try again later'
      );

      logError(error);
    });

    return () => {
      qaController.destruct();
    };
  }, [
    isWorkspaceSetupNeeded,
    qaController,
    toaster,
    hasConnectedAnyInstantApp,
  ]);

  useEffect(() => {
    if (sharedConversations.length > 0) {
      qaController.insertTopics(sharedConversations, TopicType.SHARED_WITH_ME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedConversations, qaController]);

  useEffect(() => {
    if (botsAdminsByAdminId) {
      for (const botAdmin of botsAdminsByAdminId.nodes) {
        const { bot } = botAdmin;
        const botConversations = bot.botsConversations;
        const processedConversations = botConversations.nodes
          .filter(
            (item) => item.conversation.summary && !item.conversation.archived
          )
          .map((item) => {
            const processedRes = processConversationResponse(item.conversation);

            return processedRes;
          });

        qaController.insertTopics(
          processedConversations,
          TopicType.MANAGED_BOTS,
          bot.id
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botsAdminsByAdminId, qaController]);

  useEffect(() => {
    if (!publicTopicsEnabledInOrg || !publicTopicsFeatureEnabled) {
      qaController.makeAllConversationsNonPublic();
    }
  }, [publicTopicsEnabledInOrg, qaController, publicTopicsFeatureEnabled]);

  return (
    <QAControllerProvider value={qaController}>
      <RealtimeChatProvider>
        <NotificationsProvider>
          <QAFilesProvider>
            <QATextInputBoxStatesProvider>
              {children}
            </QATextInputBoxStatesProvider>
          </QAFilesProvider>
        </NotificationsProvider>
      </RealtimeChatProvider>
    </QAControllerProvider>
  );
};
